import React, { useState, useEffect, useCallback } from "react"
import Paper from "@material-ui/core/Paper"
import GET_DOCTORS from "../../query/Doctor/getDoctors"
import { client } from "../../utilities/Apollo"
import { Loader } from "../../component/atoms/Loader"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { Wrapper } from "../../component/organisms/Wrapper"
import { Doctor } from "../../types/Doctor"
import { ApolloError, FetchResult } from "@apollo/client"
import { useSelector } from "../../store/hooks"
import { useHistory } from "react-router"
import { PageTitle } from "../../component/atoms/PageTitle"
import { ChipComponent } from "../../component/atoms/Chip"
import { colors } from "../../utilities/variables"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { IconButton } from "../../component/atoms/IconButton"
import styled from "styled-components"

type Props = {}

const StyledTableCell = styled(TableCell)`
  font-weight: 700;
`

export const Doctors: React.ComponentType<Props> = () => {
  const { user } = useSelector(state => state.user)

  const [loading, setLoading] = useState<boolean>(true)
  const [doctors, setDoctors] = useState<[Doctor] | []>([])

  const history = useHistory()
  const getDoctors = useCallback(async () => {
    setLoading(true)
    await client
      .query({ query: GET_DOCTORS })
      .then(response => onDataSuccess(response))
      .catch(error => onDataError(error))
  }, [])

  useEffect(() => {
    async function fetchData() {
      getDoctors()
    }
    fetchData()
  }, [getDoctors])

  const onDataSuccess = (response: FetchResult) => {
    setLoading(false)
    setDoctors(response?.data?.getDoctors)
  }

  const onDataError = (error: ApolloError) => {
    console.log(error)
  }

  return (
    <Wrapper user={user}>
      {loading && <Loader />}
      {!loading && (
        <div className="bg-primary-lightBlue h-full mx-8 rounded-xl p-6 border border-border-blue">
          <PageTitle
            title={"Doktoři"}
            addNewLink="/doctors/create"
            addNewTitle="Přidat doktora"
          />

          <TableContainer component={Paper} elevation={0}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{"Jméno"}</StyledTableCell>
                  <StyledTableCell>{"Ordinace"}</StyledTableCell>
                  <StyledTableCell>{"Emailová adresa"}</StyledTableCell>
                  <StyledTableCell>{"Aktivní"}</StyledTableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {doctors.map((row: Doctor) => (
                  <TableRow key={row.id}>
                    <TableCell component="td" scope="row">
                      {row.degree} {row.name}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {row.officeName}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {row.user.email}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {row.active ? (
                        <ChipComponent
                          title="Aktivní"
                          backgroundColor={colors.lightGreen}
                          textColor={colors.primaryGreen}
                        />
                      ) : (
                        <ChipComponent
                          title="Neaktivní"
                          backgroundColor={colors.lightRed}
                          textColor={colors.error}
                        />
                      )}
                    </TableCell>
                    <TableCell component="td" scope="row" align="right">
                      <IconButton
                        onClick={() =>
                          history.push("/doctors/update/" + row.uuid)
                        }
                      >
                        <FontAwesomeIcon icon={faEdit} color={colors.grey} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </Wrapper>
  )
}
