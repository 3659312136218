import { createSlice } from "@reduxjs/toolkit"

import { objectReducerGenerator } from "../objectReducerGenerator"

export interface SnackbarState {
  open: boolean
  severity?: string
  message?: string
}

const initialState: SnackbarState = {
  open: false,
  severity: "",
  message: "",
}

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    ...objectReducerGenerator<SnackbarState>(),
  },
})
