import React, { useRef, useEffect, useState } from "react"
import styled from "styled-components"
import { useFormik } from "formik"
import { Question } from "../../types/Question"
import { Answer } from "../atoms/Answer"
import FormControl from "@material-ui/core/FormControl"
import IconButton from "@material-ui/core/IconButton"
import { AnswerT } from "../../types/Answer"
import CREATE_ANSWER from "../../query/Answer/createAnswer"
import { client } from "../../utilities/Apollo"
import { ApolloError } from "@apollo/client"
import dayjs from "dayjs"
import CircularProgress from "@material-ui/core/CircularProgress"
import Rating from "@material-ui/lab/Rating"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import { colors } from "../../utilities/variables"
import { Icon } from "../atoms/Icon"
import { format, parseISO } from "date-fns"
import Alert from "@mui/material/Alert"
import classNames from "classnames"
import TextareaAutosize from "react-textarea-autosize"

type Props = {
  question: Question | null
  answers: AnswerT[] | []
  setChatInProgress: () => void
}

type ChatProps = {
  $isPaid: boolean
}

const Chat = styled.div<ChatProps>`
  flex-grow: 1;
  overflow-y: auto;
  background-color: ${p =>
    p.$isPaid ? colors.premiumChat : colors.chatBackground};
  border-left: 2px solid #b1c7da;
  border-top: 2px solid #b1c7da;
  border-right: 2px solid #b1c7da;
  max-height: 750px;
`

const ChatList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const ChatWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
`

const StyledRating = styled(Box)`
  background-color: #e4f0fa;
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid #b1c7da;
`

const StyledFormWrapper = styled.div`
  background-color: ${colors.chatBackground};
  border: 2px solid #b1c7da;
  border-top: 0px;
`

const StyledIconButton = styled(IconButton)`
  background-color: ${colors.primaryGreen};
  width: 35px;
  height: 35px;
  &:hover {
    background-color: ${colors.primaryGreen};
  }
`

export const QuestionChat: React.ComponentType<Props> = ({
  question,
  answers,
  setChatInProgress,
}) => {
  const [loading, setLoading] = useState(false)
  const messageEl = useRef<HTMLDivElement>(null)

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef<HTMLDivElement>(null)
    useEffect(() => elementRef?.current?.scrollIntoView())
    return <div ref={elementRef} />
  }

  const formik = useFormik({
    initialValues: {
      answer: "",
    },
    onSubmit: () => {
      if (!loading) {
        createAnswer()
      }
    },
  })

  function getExpirationForAnswer(
    currentDate: Date,
    createdAt: Date | undefined
  ) {
    const questionCreated = dayjs(currentDate)
    let hours = questionCreated.diff(createdAt, "hour")
    return hours
  }

  const createAnswer = async () => {
    const currentDate = new Date()

    setLoading(true)
    await client
      .mutate({
        mutation: CREATE_ANSWER,
        variables: {
          question: question?.id,
          answer: formik.values.answer,
          hours: getExpirationForAnswer(currentDate, question?.createdAt),
        },
      })
      .then(() => onCreateAnswerSuccess())
      .catch((error: ApolloError) => onCreateError(error))
  }

  const onCreateAnswerSuccess = async () => {
    setLoading(false)
    formik.resetForm()
    if (question?.status === "APPROVED") {
      if (setChatInProgress) {
        setChatInProgress()
      }
    }
  }

  const onCreateError = (error: ApolloError) => {
    setLoading(false)
    console.log(error)
  }

  if (!question) {
    return null
  }

  return (
    <ChatWrapper ref={messageEl} className={""}>
      <Chat
        className={"pt-4 px-3 rounded-tl-2xl rounded-tr-2xl"}
        $isPaid={question.isPaid}
      >
        <ChatList>
          <div
            className={classNames(
              "text-center mb-5 text-xs font-semibold text-grey-primary"
            )}
          >
            {format(parseISO(answers[0].createdAt), "d.M.Y")}
          </div>
          {answers.map(answer => {
            return (
              <Answer
                key={answer.id}
                answer={answer}
                question={question}
                text={answer.answer}
                createdAt={answer.createdAt}
              />
            )
          })}
          {(question?.status === "NEEDS_FEEDBACK" ||
            question?.status === "CLOSED") && (
            <StyledRating mb={3} borderColor="transparent">
              <Typography component="legend">
                {question?.status === "NEEDS_FEEDBACK"
                  ? "Čeká se na hodnocení dotazu uživatelem"
                  : "Udělené hodnocení"}
              </Typography>
              <Rating value={question.rating} readOnly />
            </StyledRating>
          )}
          {question.status === "REPORTED" && (
            <div>
              <Alert severity="error">Tento dotaz byl nahlášen</Alert>
            </div>
          )}
          <AlwaysScrollToBottom />
        </ChatList>
      </Chat>
      <form onSubmit={formik.handleSubmit}>
        <StyledFormWrapper
          className={"pt-3 px-3 pb-5 mb-5 rounded-bl-2xl rounded-br-2xl"}
        >
          <FormControl className={"w-full"}>
            <div className="relative">
              <TextareaAutosize
                placeholder={"Odpověď"}
                disabled={
                  question?.status === "NEEDS_FEEDBACK" ||
                  question?.status === "CLOSED" ||
                  question?.status === "REPORTED"
                }
                minRows={1}
                name="answer"
                className="focus:outline-none border py-4 pl-4 pr-12 border-border-blue rounded-3xl w-full"
                value={formik.values.answer}
                onChange={formik.handleChange}
              />
              <div className="absolute bottom-4 right-2">
                {!loading && (
                  <StyledIconButton
                    disabled={
                      loading ||
                      question?.status === "NEEDS_FEEDBACK" ||
                      question?.status === "CLOSED" ||
                      question?.status === "REPORTED"
                    }
                    color={"primary"}
                    aria-label="toggle password visibility"
                    onClick={() => formik.handleSubmit()}
                  >
                    <Icon icon={"send"} color={"text-white"} />
                  </StyledIconButton>
                )}
                {loading && <CircularProgress size={20} />}
              </div>
            </div>
          </FormControl>
        </StyledFormWrapper>
      </form>
    </ChatWrapper>
  )
}
