import React from "react"

type Props = {}

export const AdminDashboard: React.ComponentType<Props> = () => {
  return (
    <div className="bg-primary-lightBlue h-full mb-8 mx-8 rounded-xl p-10 border border-border-blue">
      <h2 className="text-xl font-extrabold">
        Přehled Veterinární poradny Buddyvet
      </h2>
    </div>
  )
}
