import { gql } from "@apollo/client"

const UPLOAD_IMAGE = gql`
  mutation uploadImage($doctor: Int!, $image: String!) {
    uploadImage(input: { doctor: $doctor, image: $image }) {
      image
    }
  }
`

export default UPLOAD_IMAGE
