import { gql } from "@apollo/client"

const GET_ALL_QUESTIONS = gql`
  query getAllQuestions($page: Int, $doctor: Int, $status: String) {
    getAllQuestions(input: { page: $page, doctor: $doctor, status: $status }) {
      limit
      count
      items {
        uuid
        id
        isPaid
        createdAt
        status
        animal {
          name
          weight
          birthday
          gender
          image
          castrated
          chronicIssues
          onMedication
          animalKind {
            id
            kind
          }
          animalRace {
            race
          }
        }
        doctor {
          degree
          name
        }
        user {
          id
          email
        }
      }
    }
  }
`

export default GET_ALL_QUESTIONS
