import { gql } from "@apollo/client"

const GET_CONFIGS = gql`
  query {
    getConfigs {
      id
      identifier
      value
    }
  }
`

export default GET_CONFIGS
