import { gql } from "@apollo/client"

const GET_DOCTORS = gql`
  query {
    getDoctors {
      uuid
      id
      degree
      name
      officeName
      description
      image
      street
      city
      zip
      active
      user {
        id
        email
      }
    }
  }
`

export default GET_DOCTORS
