import { createSlice } from "@reduxjs/toolkit"

import { objectReducerGenerator } from "../objectReducerGenerator"

export interface ModalState {
  closeQuestion?: boolean
  reportQuestion?: boolean
  uploadAvatar?: boolean
}

const initialState: ModalState = {
  closeQuestion: false,
  reportQuestion: false,
  uploadAvatar: false,
}

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    ...objectReducerGenerator<ModalState>(),
  },
})
