import React, { useState, useEffect, useCallback } from "react"
import { makeStyles } from "@material-ui/core/styles"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { client } from "../../utilities/Apollo"
import { Loader } from "../../component/atoms/Loader"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Button from "@material-ui/core/Button"
import GET_ALL_QUESTIONS from "../../query/Question/getAllQuestions"
import DELETE_QUESTION from "../../query/Question/deleteQuestion"
import { StatusChip } from "../../component/atoms/StatusChip"
import dayjs from "dayjs"
import { Wrapper } from "../../component/organisms/Wrapper"
import { Question, QuestionStatus } from "../../types/Question"
import { ApolloError, FetchResult } from "@apollo/client"
import { QuestionDeleteDialog } from "../../component/dialogs/QuestionDeleteDialog"
import { MenuDropdown } from "../../component/atoms/MenuDropdown"
import { useSelector } from "../../store/hooks"
import { BlueWrapper } from "../../component/atoms/BlueWrapper"
import { PageTitle } from "../../component/atoms/PageTitle"
import GET_QUESTION_FILTER from "../../query/Question/getQuestionFilters"
import { FilterDoctors } from "../../component/molecules/FilterDoctors"
import { Doctor } from "../../types/Doctor"
import { FilterStatuses } from "../../component/molecules/FilterStatuses"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    backgroundColor: "#f3f7fc",
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  buttonMargin: {
    marginLeft: theme.spacing(2),
  },

  alignCenter: {
    textAlign: "center",
  },
}))

interface DataProps {
  questions: [Question] | []
  page: number
  prevArrowHidden: boolean
  nextArrowHidden: boolean
  openDialog: (question: Question) => void
  changePage: (page: number) => void
}

const StyledTableCell = styled(TableCell)`
  font-weight: 700;
`

const DataContainer = (props: DataProps) => {
  const {
    questions,
    openDialog,
    changePage,
    page,
    prevArrowHidden,
    nextArrowHidden,
  } = props
  const classes = useStyles()

  const getCreatedAt = (date: Date) => {
    return dayjs(date).format("D. M. YYYY H:mm:ss")
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>{"Mazlíček"}</StyledTableCell>
              <StyledTableCell>{"Datum vytvoření"}</StyledTableCell>
              <StyledTableCell>{"Emailová adresa"}</StyledTableCell>
              <StyledTableCell>{"Vetrinář"}</StyledTableCell>
              <StyledTableCell>{"Status"}</StyledTableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questions.map((row: Question) => (
              <TableRow key={row.id}>
                <TableCell component="td" scope="row">
                  #{row.id}
                </TableCell>
                <TableCell component="td" scope="row">
                  {row.animal.name}
                </TableCell>
                <TableCell component="td" scope="row">
                  {getCreatedAt(row.createdAt)}
                </TableCell>
                <TableCell component="td" scope="row">
                  {row.user.email}
                </TableCell>
                <TableCell component="td" scope="row">
                  {row.doctor.degree} {row.doctor.name}
                </TableCell>
                <TableCell component="td" scope="row">
                  <StatusChip status={row.status} />
                </TableCell>
                <TableCell component="td" scope="row" align="right">
                  <MenuDropdown data={row} openDialog={openDialog} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid item xs={12} className={classes.alignCenter}>
        {!prevArrowHidden && (
          <Button
            className={classes.buttonMargin}
            color={"secondary"}
            variant="contained"
            onClick={() => changePage(page - 1)}
          >
            Předchozí
          </Button>
        )}
        {!nextArrowHidden && (
          <Button
            size={"large"}
            color={"primary"}
            className={classes.buttonMargin}
            variant="contained"
            onClick={() => changePage(page + 1)}
          >
            Další
          </Button>
        )}
      </Grid>
    </>
  )
}

type Props = {}
export const Questions: React.ComponentType<Props> = () => {
  const { user } = useSelector(state => state.user)

  const [loading, setLoading] = useState(true)
  const [loadingFilters, setLoadingFilters] = useState(true)
  const [doctors, setDoctors] = useState<[Doctor] | []>([])
  const [statuses, setStatuses] = useState<[QuestionStatus] | []>([])
  const [questions, setQuestions] = useState<[Question] | []>([])
  const [page, setPage] = useState(0)
  const [deleteDrawer, setDeleteDrawer] = useState(false)
  const [questionToBeDeleted, setQuestionToBeDeleted] =
    useState<Question | null>(null)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [prevArrowHidden, setPrevArrowHidden] = useState(false)
  const [nextArrowHidden, setNextArrowHidden] = useState(false)

  const openDialog = (question: Question) => {
    setDeleteDrawer(true)
    setQuestionToBeDeleted(question)
  }

  const changePage = async (newPage: number) => {
    setPage(newPage)
    setQuestions([])
  }

  const changePageCallback = useCallback(newPage => changePage(newPage), [])
  const getQuestions = useCallback(
    async (page: number, doctor?: number, status?: string) => {
      setLoading(true)
      await client
        .query({
          query: GET_ALL_QUESTIONS,
          variables: { page: page, doctor: doctor, status: status },
        })
        .then(response => onDataSuccess(response, page))
        .catch(error => onDataError(error))
    },
    []
  )

  const getQuestionFilters = useCallback(async () => {
    setLoadingFilters(true)
    await client
      .query({ query: GET_QUESTION_FILTER })
      .then(response => onFiltersSuccess(response))
      .catch(error => onDataError(error))
  }, [])

  useEffect(() => {
    async function fetchData() {
      getQuestions(page)
      getQuestionFilters()
    }
    fetchData()
  }, [page, getQuestions, getQuestionFilters])

  const onDataSuccess = (response: FetchResult, page: number) => {
    setQuestions(response?.data?.getAllQuestions.items)

    if (page > 0) {
      setPrevArrowHidden(false)
    }

    if (page === 0) {
      setPrevArrowHidden(true)
    }

    if (
      response?.data?.getAllQuestions.count <=
      response?.data?.getAllQuestions.limit
    ) {
      setNextArrowHidden(true)
    } else {
      setNextArrowHidden(false)
    }

    if (
      response?.data?.getAllQuestions.count <=
      response?.data?.getAllQuestions.limit * (page + 1)
    ) {
      setNextArrowHidden(true)
    } else {
      setNextArrowHidden(false)
    }
    setLoading(false)
  }

  const onDataError = (error: ApolloError) => {
    console.log(error)
  }

  const onFiltersSuccess = (response: FetchResult) => {
    setDoctors(response?.data?.getQuestionFilters.vets)
    setStatuses(response?.data?.getQuestionFilters.statuses)
    setLoadingFilters(false)
  }

  const deleteQuestion = async () => {
    setLoadingDelete(true)
    await client
      .mutate({
        mutation: DELETE_QUESTION,
        variables: { question: questionToBeDeleted?.id },
      })
      .then(response => onDeleteQuestionSuccess(response))
      .catch(error => onDeleteError(error))
  }

  const onDeleteQuestionSuccess = async (response: FetchResult) => {
    setLoadingDelete(false)
    setDeleteDrawer(false)
    getQuestions(page)
  }

  const onDeleteError = (error: ApolloError) => {
    setLoadingDelete(false)
    console.log(error)
  }
  const [selectedDoctor, setSelectedDoctor] = React.useState<number>(0)
  const [selectedStatus, setSelectedStatus] = React.useState<string>("")
  const clearFilterDoctor = async () => {
    setSelectedDoctor(0)
    await getQuestions(0, 0, selectedStatus)
  }

  const filterByDoctor = async (doctor: number) => {
    setSelectedDoctor(doctor)
    await getQuestions(0, doctor, selectedStatus)
  }

  const filterByStatus = async (status: string) => {
    setSelectedStatus(status)
    await getQuestions(0, selectedDoctor, status)
  }

  const clearFilterStatus = async () => {
    setSelectedStatus("")
    await getQuestions(0, selectedDoctor, "")
  }
  return (
    <Wrapper user={user}>
      <BlueWrapper>
        <PageTitle title={"Dotazy"} />
        {loading && <Loader />}
        {!loadingFilters && (
          <div className="flex flex-wrap gap-1 mb-4">
            {doctors.length > 0 && (
              <FilterDoctors
                clear={clearFilterDoctor}
                doctors={doctors}
                apply={filterByDoctor}
              />
            )}
            {statuses.length > 0 && (
              <FilterStatuses
                clear={clearFilterStatus}
                statuses={statuses}
                apply={filterByStatus}
              />
            )}
          </div>
        )}
        {!loading && (
          <DataContainer
            prevArrowHidden={prevArrowHidden}
            nextArrowHidden={nextArrowHidden}
            page={page}
            changePage={changePageCallback}
            questions={questions}
            openDialog={openDialog}
          />
        )}
      </BlueWrapper>
      <QuestionDeleteDialog
        setDeleteDrawer={setDeleteDrawer}
        deleteDrawer={deleteDrawer}
        deleteQuestion={deleteQuestion}
        loadingDelete={loadingDelete}
      />
    </Wrapper>
  )
}
