import styled from "styled-components"
import Dialog from "@mui/material/Dialog"
import { useDispatch, useSelector } from "../../store/hooks"
import { modalSlice } from "../../store/slices/modal"
import CloseIcon from "@mui/icons-material/Close"
import { FabButton } from "../atoms/FabButton"
export interface Props {
  modalId: "closeQuestion" | "reportQuestion" | "uploadAvatar"
  title?: string
  onClose?: () => void
}

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    width: calc(100% - 64px);
    overflow: inherit;
  }
`

export const Modal: React.FC<Props> = ({
  modalId,
  children,
  title,
  onClose,
}) => {
  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(
      modalSlice.actions.update({
        [modalId]: false,
      })
    )
    if (onClose) {
      onClose()
    }
  }

  const open = useSelector(state => state.modal[modalId])

  return (
    <StyledDialog open={open as boolean} onClose={handleClose} maxWidth={"sm"}>
      <div className={"absolute -right-6 -top-6"}>
        <FabButton onClick={handleClose}>
          <CloseIcon />
        </FabButton>
      </div>

      <div className={"p-8"}>
        {title && <p className={"font-semibold text-lg mb-3"}>{title}</p>}
        {children}
      </div>
    </StyledDialog>
  )
}
