import React from "react"
import FilledCheck from "../../assets/icons/selected.svg"
import Check from "../../assets/icons/icon-check.svg"

type Props = {
  id: number | string
  title: string
  onClick: (id: any) => void
  isSelected: boolean
}

export const FilterItem: React.ComponentType<Props> = ({
  id,
  title,
  onClick,
  isSelected,
}) => {
  return (
    <div
      onClick={() => onClick(id)}
      className="flex justify-between items-center p-1 cursor-pointer hover:bg-gray-100"
    >
      <div className="text-sm font-medium">{title}</div>
      <div>
        {!isSelected && <img src={Check} alt={"Unchecked item"} />}
        {isSelected && <img src={FilledCheck} alt={"Checked item"} />}
      </div>
    </div>
  )
}
