import { ApolloError, FetchResult } from "@apollo/client"
import {
  Box,
  Card,
  CardContent,
  Drawer,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core"
import React, { useState, useEffect, useCallback } from "react"
import { REQUIRED } from "../../constants/constants"
import { LoaderButton } from "../atoms/LoaderButton"
import { client } from "../../utilities/Apollo"
import { CREATE_NOTIFICATION } from "../../query/Notification/createNotification"
import { useFormik, FormikErrors } from "formik"
import styled from "styled-components"
import { UPDATE_NOTIFICATION } from "../../query/Notification/updateNotification"
import { Notification } from "../../types/Notification"
import DateFnsUtils from "@date-io/date-fns"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"

const StyledDrawer = styled.div`
  width: 440px;
`

interface DialogProps {
  createDrawer: boolean
  getNotifications: () => void
  setCreateDrawer: (bool: boolean) => void
  notification?: Notification
}

interface FormValues {
  text: string
  receivers: string
  toBeSent: Date
}

export const NotificationDialog: React.FC<DialogProps> = ({
  setCreateDrawer,
  createDrawer,
  getNotifications,
  notification,
}) => {
  const anchor = "right"

  const validate = (values: FormValues) => {
    let errors: FormikErrors<FormValues> = {}
    if (!values.text) {
      errors.text = REQUIRED
    }
    if (!values.receivers) {
      errors.receivers = REQUIRED
    }
    if (!values.toBeSent) {
      errors.toBeSent = REQUIRED
    }

    return errors
  }

  const formik = useFormik({
    initialValues: {
      text: "",
      receivers: "all",
      toBeSent: new Date(),
    },
    validate,
    onSubmit: () => {
      if (notification) {
        updateNotification()
      } else {
        createNotification()
      }
    },
  })

  const [loading, setLoading] = useState(false)

  const setFormValues = useCallback((notification: Notification) => {
    formik.setFieldValue("text", notification.text)
    formik.setFieldValue("toBeSent", notification.toBeSent)
    formik.setFieldValue("receivers", notification.receivers)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (notification) {
      setFormValues(notification)
    }
  }, [notification, setFormValues])

  const createNotification = async () => {
    setLoading(true)
    await client
      .mutate({
        mutation: CREATE_NOTIFICATION,
        variables: {
          text: formik.values.text,
          toBeSent: formik.values.toBeSent,
          receivers: formik.values.receivers,
        },
      })
      .then((response: FetchResult) => onCreateNotificaionSuccess(response))
      .catch((error: ApolloError) => onCreateError(error))
  }

  const onCreateNotificaionSuccess = async (response: FetchResult) => {
    setLoading(false)
    setCreateDrawer(false)
    getNotifications()
    formik.resetForm()
  }

  const onCreateError = (error: ApolloError) => {
    setLoading(false)
    console.log(error)
  }

  const updateNotification = async () => {
    setLoading(true)
    await client
      .mutate({
        mutation: UPDATE_NOTIFICATION,
        variables: {
          id: notification?.id,
          text: formik.values.text,
          toBeSent: formik.values.toBeSent,
          receivers: formik.values.receivers,
        },
      })
      .then(() => onUpdateNotificaionSuccess())
      .catch((error: ApolloError) => onUpdateError(error))
  }

  const onUpdateNotificaionSuccess = async () => {
    setLoading(false)
    setCreateDrawer(false)
    getNotifications()
  }

  const onUpdateError = (error: ApolloError) => {
    setLoading(false)
    console.log(error)
  }

  const handleDrawerClose = () => {
    setCreateDrawer(false)
    formik.resetForm()
  }

  return (
    <React.Fragment key={anchor}>
      <Drawer
        key={"right"}
        anchor={anchor}
        open={createDrawer}
        onClose={() => handleDrawerClose()}
      >
        <StyledDrawer role="presentation">
          <Card elevation={0}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {notification ? "Upravit " : "Přidat "} notifikaci
              </Typography>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      margin="dense"
                      fullWidth
                      id="text"
                      label={"Text"}
                      name="text"
                      onChange={formik.handleChange}
                      value={formik.values.text}
                      helperText={formik.errors.text || " "}
                      error={
                        (formik.errors.text && formik.touched.text) || undefined
                      }
                    />
                  </Grid>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item xs={12}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="toBeSent"
                        label="Datum odeslání"
                        value={formik.values.toBeSent}
                        onChange={date => {
                          formik.setFieldValue("toBeSent", date)
                        }}
                        helperText={formik.errors.toBeSent || " "}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    margin="dense"
                    fullWidth
                    id="receivers"
                    label={"Příjemci"}
                    name="receivers"
                    onChange={formik.handleChange}
                    value={formik.values.receivers}
                    helperText={formik.errors.receivers || " "}
                    error={
                      (formik.errors.receivers && formik.touched.receivers) ||
                      undefined
                    }
                  />
                </Grid>
                <Box mt={3}>
                  <LoaderButton
                    type="submit"
                    primary={true}
                    loading={loading}
                    title={
                      notification ? "Upravit notifikaci" : "Přidat notifikaci"
                    }
                    onClick={() => {}}
                  />
                </Box>
              </form>
            </CardContent>
          </Card>
        </StyledDrawer>
      </Drawer>
    </React.Fragment>
  )
}
