export const colors = {
  iconGrey: "#BBC5D5",
  petBorder: "#DBE5ED",
  petBackground: "#F4F5F8",
  primaryGreen: "#76C00D",
  primaryBlue: "#28516B",
  chatBackground: "#FCFEFF",
  userChat: "#E2ECF5",
  white: "#FFFFFF",
  nearlyBlack: "#4B5155",
  answer: "#7B8793",
  error: "#960B06",
  inputField: "#EDF1F6",
  grey: "#8792AF",
  lightRed: "#ff434326",
  lightGreen: "#dff5ea",
  premiumChat: "#fff5d1",
}

export const translations = {
  required: "Feltet er påkrævet",
}
