import { gql } from "@apollo/client"

const GET_CURRENT_USER = gql`
  query getCurrentAdminDoctor {
    currentAdminDoctor {
      id
      uuid
      email
      name
      lastName
      role {
        id
        name
      }
      doctor {
        id
        name
        degree
        image
      }
    }
  }
`

export default GET_CURRENT_USER
