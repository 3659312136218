import { gql } from "@apollo/client"

const UPDATE_CONFIG = gql`
  mutation updateConfig($id: Int!, $value: String!) {
    updateConfig(input: { id: $id, value: $value }) {
      id
    }
  }
`

export default UPDATE_CONFIG
