import React from "react"
import styled from "styled-components"

type Props = {
  onClick: () => void
}

const StyledIconButton = styled.span`
  cursor: pointer;
  padding: 5px;
  display: inline-flex;
  width: 34px;
  align-items: center;
  justify-content: center;
  height: 34px;
`

export const IconButton: React.ComponentType<Props> = ({
  onClick,
  children,
}) => {
  return <StyledIconButton onClick={onClick}>{children}</StyledIconButton>
}
