import { configureStore } from "@reduxjs/toolkit"
import { modalSlice } from "./slices/modal"
import { snackbarSlice } from "./slices/snackbarSlice"

import { userSlice } from "./slices/userSlice"

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    snackbar: snackbarSlice.reducer,
    modal: modalSlice.reducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
