import React from "react"
import styled from "styled-components"
import { Doctor } from "../../types/Doctor"
import { QuestionStatus } from "../../types/Question"
import { colors } from "../../utilities/variables"

type Props = {
  title: string
  onClick: (e: any) => void
  count: number | string
  doctors?: [Doctor] | []
  statuses?: [QuestionStatus] | []
}
const StyledFilterButton = styled(props => <button {...props} />)`
  border: 1px solid ${colors.grey};
  border-radius: 60px;
  height: 36px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  background-color: ${props =>
    props.$isActive ? colors.nearlyBlack : "transparent"};
  color: ${props => (props.$isActive ? colors.white : colors.nearlyBlack)};
`

export const FilterButton: React.ComponentType<Props> = ({
  title,
  onClick,
  count,
  doctors,
  statuses,
}) => {
  const getDoctorName = () => {
    const doctor = doctors?.find(d => d.id === count)
    if (doctor) {
      return doctor.degree + " " + doctor.name
    }
    const status = statuses?.find(s => s.value === count)
    if (status) {
      return status.label
    }
    return null
  }

  return (
    <StyledFilterButton
      onClick={onClick}
      $isActive={
        count !== 0 &&
        count !== "" &&
        count !== null &&
        typeof count !== "undefined"
      }
    >
      {title}
      {count !== 0 &&
        count !== "" &&
        count !== null &&
        typeof count !== "undefined" && (
          <span className="ml-2 text-xs">({getDoctorName()})</span>
        )}
    </StyledFilterButton>
  )
}
