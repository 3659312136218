import { gql } from "@apollo/client"

export const CREATE_NOTIFICATION = gql`
  mutation createNotificaion(
    $text: String!
    $toBeSent: Date!
    $receivers: String!
  ) {
    createNotification(
      input: { text: $text, toBeSent: $toBeSent, receivers: $receivers }
    ) {
      id
    }
  }
`
