import { gql } from "@apollo/client"

export const UPDATE_NOTIFICATION = gql`
  mutation updateNotificaion(
    $id: Int!
    $text: String!
    $toBeSent: Date!
    $receivers: String!
  ) {
    updateNotification(
      input: {
        id: $id
        text: $text
        toBeSent: $toBeSent
        receivers: $receivers
      }
    ) {
      id
    }
  }
`
