import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"
import React from "react"

type Props = {}

export const Copyright: React.ComponentType<Props> = () => {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      className="p-3"
    >
      {"Copyright © "}
      <Link color="inherit" href="https://buddyvet.cz/" target={"_blank"}>
        BuddyVet.cz
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}
