import React from "react"
import styled from "styled-components"
import { AnswerT } from "../../types/Answer"
import { Question } from "../../types/Question"
import classNames from "classnames"
import { format, parseISO } from "date-fns"
import { SRLWrapper } from "simple-react-lightbox"
import { colors } from "../../utilities/variables"

type Props = {
  answer?: AnswerT
  question: Question
  text: string
  createdAt?: string
}

const StyledAnswerWrapper = styled.li`
  margin-bottom: 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${colors.userChat};
  &.me {
    margin-left: 2rem;
    align-self: flex-end;
  }
  &.you {
    margin-right: 2rem;
  }
`

const StyledAnswerBackground = styled.span`
  margin-bottom: 0.2rem;
  position: relative;
  color: #363636;
  border-radius: 10px;
  background-color: #ffffff;
  max-width: 30rem;
  font-size: 1rem;
  overflow: hidden;
  overflow-wrap: break-word;
  word-break: break-word;
  box-shadow: 0px 2px 4px rgba(77, 111, 133, 0.1);
  &.me {
    margin-left: 2rem;
    border: 1px solid #d8e1e9;
    background-color: ${colors.white};
    align-self: flex-end;
    color: ${colors.primaryBlue};
  }
  &.you {
    margin-right: 2rem;
    color: ${colors.primaryBlue};
    background-color: ${colors.userChat};
  }
  img {
    padding: 6px;
    border-radius: 14px;
  }
`

const ChatTime = styled.div`
  font-size: 10px;
  text-align: right;
  align-self: flex-end;
`

export const Answer: React.ComponentType<Props> = ({
  answer,
  question,
  text,
  createdAt,
}) => {
  const options = {
    buttons: {
      showAutoplayButton: false,
      showCloseButton: true,
      showDownloadButton: true,
      showFullscreenButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
    },
    thumbnails: {
      showThumbnails: false,
    },
  }
  return (
    <StyledAnswerWrapper
      className={classNames({
        me: answer?.owner.id === question.doctor.user.id,
        you: answer?.owner.id !== question.doctor.user.id,
      })}
    >
      <StyledAnswerBackground
        className={classNames({
          me: answer?.owner.id === question.doctor.user.id,
          you: answer?.owner.id !== question.doctor.user.id,
        })}
      >
        {text && <div className="p-4">{text}</div>}
        {answer?.media !== null && answer?.type === "image" && (
          <SRLWrapper options={options}>
            <div id="content-page-one">
              <img src={answer?.media} alt={""} className={"cursor-pointer"} />
            </div>
          </SRLWrapper>
        )}
        {createdAt && (
          <ChatTime
            className={classNames(
              {
                me: answer?.owner.id === question.doctor.user.id,
                you: answer?.owner.id !== question.doctor.user.id,
              },
              "pb-1 pr-2"
            )}
          >
            {format(parseISO(createdAt), "H:mm")}
          </ChatTime>
        )}
      </StyledAnswerBackground>
    </StyledAnswerWrapper>
  )
}
