import Drawer from "@material-ui/core/Drawer"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import styled from "styled-components"
import { LoaderButton } from "../atoms/LoaderButton"

interface QuestionDeletedProps {
  setDeleteDrawer: (bool: boolean) => void
  deleteDrawer: boolean
  deleteQuestion: () => void
  loadingDelete: boolean
}

const StyledDrawer = styled.div`
  width: 440px;
`

export const QuestionDeleteDialog: React.FC<QuestionDeletedProps> = ({
  setDeleteDrawer,
  deleteDrawer,
  deleteQuestion,
  loadingDelete,
}) => {
  const anchor = "right"

  return (
    <>
      <Drawer
        key={"right"}
        anchor={anchor}
        open={deleteDrawer}
        onClose={() => setDeleteDrawer(false)}
      >
        <StyledDrawer role="presentation">
          <Card elevation={0}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Smazat dotaz
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Jste si jisti, že chcete tento dotaz smazat?
              </Typography>
            </CardContent>
            <CardActions>
              <LoaderButton
                title={"Smazat dotaz"}
                loading={loadingDelete}
                onClick={() => deleteQuestion()}
              />

              <Button size="small" onClick={() => setDeleteDrawer(false)}>
                Zavřít
              </Button>
            </CardActions>
          </Card>
        </StyledDrawer>
      </Drawer>
    </>
  )
}
