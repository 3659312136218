import { gql } from "@apollo/client"

const CLOSE_QUESTION = gql`
  mutation closeQuestion($question: Int!) {
    closeQuestion(question: $question) {
      id
      status
    }
  }
`

export default CLOSE_QUESTION
