import { Question } from "../../types/Question"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import IconButton from "@material-ui/core/IconButton"
import Icon from "@mdi/react"
import { mdiDotsVertical } from "@mdi/js"
import { useHistory } from "react-router-dom"
import React, { useState } from "react"

interface DotsProps {
  data: Question
  openDialog: (question: Question) => void
}

export const MenuDropdown: React.FC<DotsProps> = ({ data, openDialog }) => {
  let history = useHistory()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const openDialogAndClose = (data: Question) => {
    openDialog(data)
    setAnchorEl(null)
  }

  const goToDetail = (id: string) => {
    history.push("/questions/" + id)
  }

  return (
    <React.Fragment>
      <IconButton onClick={handleClick}>
        <Icon path={mdiDotsVertical} size={1} />
      </IconButton>
      <Menu
        id="card-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => goToDetail(data.uuid)}>Detail</MenuItem>
        <MenuItem onClick={() => openDialogAndClose(data)}>Smazat</MenuItem>
      </Menu>
    </React.Fragment>
  )
}
