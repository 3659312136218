import React, { useState, useEffect, useCallback } from "react"
import { makeStyles } from "@material-ui/core/styles"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { client } from "../../utilities/Apollo"
import { Loader } from "../../component/atoms/Loader"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Button from "@material-ui/core/Button"
import { Wrapper } from "../../component/organisms/Wrapper"
import { ApolloError, FetchResult } from "@apollo/client"
import { useSelector } from "../../store/hooks"
import { BlueWrapper } from "../../component/atoms/BlueWrapper"
import { PageTitle } from "../../component/atoms/PageTitle"
import GET_ALL_USERS from "../../query/User/getAllUsers"
import { User } from "../../types/User"
import IconButton from "@mui/material/IconButton"
import ChevronRight from "@mui/icons-material/ChevronRight"
import InputBase from "@mui/material/InputBase"
import Divider from "@mui/material/Divider"
import Reset from "@mui/icons-material/Close"
import { useHistory } from "react-router"
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    backgroundColor: "#f3f7fc",
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  buttonMargin: {
    marginLeft: theme.spacing(2),
  },

  alignCenter: {
    textAlign: "center",
    marginTop: "20px",
  },
}))

interface DataProps {
  users: [User] | []
  page: number
  prevArrowHidden: boolean
  nextArrowHidden: boolean
  changePage: (page: number) => void
}

const StyledTableCell = styled(TableCell)`
  font-weight: 700;
`

const DataContainer = (props: DataProps) => {
  const { users, changePage, page, prevArrowHidden, nextArrowHidden } = props
  const classes = useStyles()
  let history = useHistory()

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>{"Email"}</StyledTableCell>
              <StyledTableCell>{"Jméno"}</StyledTableCell>
              <StyledTableCell align="right">{"Detail"}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((row: User) => (
              <TableRow key={row.id}>
                <TableCell component="td" scope="row">
                  #{row.id}
                </TableCell>
                <TableCell component="td" scope="row">
                  {row.email ?? "-"}
                </TableCell>

                <TableCell component="td" scope="row">
                  {row.name ?? "-"}
                </TableCell>

                <TableCell component="td" scope="row" align="right">
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={() => history.push(`/users/${row.uuid}`)}
                  >
                    <ChevronRight />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid item xs={12} className={classes.alignCenter}>
        {!prevArrowHidden && (
          <Button
            className={classes.buttonMargin}
            color={"secondary"}
            variant="contained"
            onClick={() => changePage(page - 1)}
          >
            Předchozí
          </Button>
        )}
        {!nextArrowHidden && (
          <Button
            size={"large"}
            color={"primary"}
            className={classes.buttonMargin}
            variant="contained"
            onClick={() => changePage(page + 1)}
          >
            Další
          </Button>
        )}
      </Grid>
    </>
  )
}

type Props = {}
export const Users: React.ComponentType<Props> = () => {
  const { user } = useSelector(state => state.user)

  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState<[User] | []>([])
  const [page, setPage] = useState(0)
  const [prevArrowHidden, setPrevArrowHidden] = useState(false)
  const [nextArrowHidden, setNextArrowHidden] = useState(false)
  const [showReset, setShowReset] = useState(false)

  const changePage = async (newPage: number) => {
    setPage(newPage)
    setUsers([])
  }

  const changePageCallback = useCallback(newPage => changePage(newPage), [])
  const getUsers = useCallback(async (page: number, email: string) => {
    setLoading(true)
    if (email) {
      setShowReset(true)
    } else {
      setEmail("")
      setShowReset(false)
    }
    await client
      .query({
        query: GET_ALL_USERS,
        variables: { page: page, email: email },
      })
      .then(response => onDataSuccess(response, page))
      .catch(error => onDataError(error))
  }, [])

  useEffect(() => {
    async function fetchData() {
      getUsers(page, "")
    }
    fetchData()
  }, [page, getUsers])

  const onDataSuccess = (response: FetchResult, page: number) => {
    setUsers(response?.data?.getAllUsers.items)

    if (page > 0) {
      setPrevArrowHidden(false)
    }

    if (page === 0) {
      setPrevArrowHidden(true)
    }

    if (
      response?.data?.getAllUsers.count <= response?.data?.getAllUsers.limit
    ) {
      setNextArrowHidden(true)
    } else {
      setNextArrowHidden(false)
    }

    if (
      response?.data?.getAllUsers.count <=
      response?.data?.getAllUsers.limit * (page + 1)
    ) {
      setNextArrowHidden(true)
    } else {
      setNextArrowHidden(false)
    }
    setLoading(false)
  }

  const onDataError = (error: ApolloError) => {
    console.log(error)
  }

  const [email, setEmail] = React.useState("")
  const handleChange = (event: any) => {
    setEmail(event.target.value)
  }
  return (
    <Wrapper user={user}>
      <BlueWrapper>
        <PageTitle title={"Uživatelé"} />
        {loading && <Loader />}
        {!loading && (
          <>
            <Paper
              component="form"
              style={{
                padding: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
                marginBottom: "15px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1, fontFamily: "Nunito" }}
                placeholder="Vyhledat email"
                inputProps={{ "aria-label": "search google maps" }}
                value={email}
                onChange={handleChange}
              />

              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                onClick={() => getUsers(page, email)}
                color="primary"
                sx={{ p: "10px" }}
              >
                <ChevronRight />
              </IconButton>
              {showReset && (
                <IconButton
                  onClick={() => getUsers(page, "")}
                  color="secondary"
                  sx={{ p: "10px" }}
                >
                  <Reset />
                </IconButton>
              )}
            </Paper>

            <DataContainer
              prevArrowHidden={prevArrowHidden}
              nextArrowHidden={nextArrowHidden}
              page={page}
              changePage={changePageCallback}
              users={users}
            />
          </>
        )}
      </BlueWrapper>
    </Wrapper>
  )
}
