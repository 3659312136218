import { gql } from "@apollo/client"

const UPDATE_DOCTOR = gql`
  mutation updateDoctor(
    $id: Int!
    $name: String!
    $street: String!
    $city: String!
    $zip: String!
    $officeName: String!
    $degree: String!
    $description: String
    $active: Boolean
  ) {
    updateDoctor(
      input: {
        id: $id
        name: $name
        street: $street
        city: $city
        zip: $zip
        officeName: $officeName
        description: $description
        degree: $degree
        active: $active
      }
    ) {
      id
      uuid
    }
  }
`

export default UPDATE_DOCTOR
