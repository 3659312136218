import { gql } from '@apollo/client';

const APPROVE_QUESTION = gql`
    mutation approveQuestion($question:Int!) {
        approveQuestion(
            question: $question
        )
    }
`;

export default APPROVE_QUESTION;
