import React from "react"
import styled from "styled-components"
import LoadingButton from "@mui/lab/LoadingButton"
import classNames from "classnames"
import { colors } from "../../utilities/variables"

type Props = {
  onClick: () => void
  loading: boolean
  title: string
  primary?: boolean
  backgroundColor?: string
  defineHeight?: boolean
  type?: string
  borderRadius?: number
}

const Wrapper = styled(props => <div {...props} />)``

const StyledLoadingButton = styled(props => <LoadingButton {...props} />)`
  ${({ $backgroundColor }) =>
    $backgroundColor &&
    `
    background-color: ${$backgroundColor} !important;
  `}
  ${({ $primary }) =>
    !$primary &&
    `
    width: 150px;
  `}
  border-radius: ${props => props.$radius}px !important;
`

const ButtonTitle = styled.span`
  font-family: Nunito;
`

export const LoaderButton: React.ComponentType<Props> = ({
  onClick,
  loading,
  title,
  primary,
  backgroundColor = colors.primaryGreen,
  defineHeight = true,
  type = "button",
  borderRadius = 20,
}) => {
  return (
    <Wrapper className={"my-4"}>
      <StyledLoadingButton
        $radius={borderRadius}
        $backgroundColor={backgroundColor}
        $primary={primary}
        type={type}
        loading={loading}
        onClick={onClick}
        className={classNames("flex focus:outline-none justify-center", {
          "bg-primary-green w-full": primary,
          "h-12": defineHeight,
        })}
      >
        {!loading && (
          <ButtonTitle
            className={"text-white normal-case font-extrabold text-base"}
          >
            {title}
          </ButtonTitle>
        )}
      </StyledLoadingButton>
    </Wrapper>
  )
}
