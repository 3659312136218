import { IconsId } from "../../assets/fonts/iconfont/icons";
import classNames from "classnames";
import * as React from "react";

type Props = {
  icon: IconsId;
  size?: string;
  color?: string;
  align?: string;
  className?: string;
};

export const Icon: React.ComponentType<Props> = ({
  icon,
  size = "sm:text-lg text-xl",
  color,
  className,
  align,
}) => {
  return (
    <span
      className={classNames(`icon-${icon}`, "inline-flex", size, color, className)}
    ></span>
  );
};
