import { gql } from "@apollo/client"

const CREATE_ANSWER = gql`
  mutation createAsnwer($question: Int!, $answer: String!, $hours: Int!) {
    createAnswer(
      input: { question: $question, answer: $answer, hours: $hours }
    ) {
      id
      uuid
      answer
      createdAt
      owner {
        id
      }
    }
  }
`

export default CREATE_ANSWER
