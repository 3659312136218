import styled from "styled-components"
import ListItem, { ListItemProps } from "@material-ui/core/ListItem"
import { Link, useLocation } from "react-router-dom"
import { colors } from "../../utilities/variables"

export interface MenuLinkProps {
  label: string
  link: string
  icon: string
}

const StyledListItemLink = styled(ListItemLink)`
  background-color: ${props =>
    props.selected ? "transparent !important" : "transparent"};
  color: ${props =>
    props.selected ? colors.primaryGreen : colors.primaryBlue};
  font-size: 1rem;
  font-weight: 800;
  padding-left: 0;
`
function ListItemLink(props: ListItemProps<Link, { button?: true }>) {
  return <ListItem button component={Link} {...props} />
}

export const MenuLink: React.FC<MenuLinkProps> = ({ label, link }) => {
  const location = useLocation()
  return (
    <StyledListItemLink
      button
      to={link}
      selected={location.pathname.includes(link)}
    >
      {label}
    </StyledListItemLink>
  )
}
