import React from "react"
import ReactDOM from "react-dom"
import reportWebVitals from "./reportWebVitals"
import { Provider } from "react-redux"
import { store } from "./store"
import AppRouter from "./route/Routing"
import { createTheme } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles"
import { BrowserRouter as Router } from "react-router-dom"
import { createBrowserHistory } from "history"
import "./styles.css"
import "./assets/fonts/iconfont/icons.css"
import SimpleReactLightbox from "simple-react-lightbox"

export const history = createBrowserHistory()
const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#002641",
    },
  },
  typography: {
    fontFamily: ["Nunito", "sans-serif"].join(","),
  },
})

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <SimpleReactLightbox>
        <Router>
          <AppRouter />
        </Router>
      </SimpleReactLightbox>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
