import React from "react"
import classNames from "classnames"

type Props = {
  background?: string
}

export const BlueWrapper: React.ComponentType<Props> = ({
  children,
  background = "bg-primary-lightBlue",
}) => {
  return (
    <div
      className={classNames(
        "min-h-full mx-8 rounded-xl p-6 border border-border-blue",
        background
      )}
    >
      {children}
    </div>
  )
}
