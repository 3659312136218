import React from "react"
import jwt_decode from "jwt-decode"
import { Redirect } from "react-router-dom"

export function authenticatedPage(Component, roleId) {
  const componentName = Component.displayName || Component.name || "Component"

  return class extends React.Component {
    static displayName = `Route(${componentName})`

    renderPage() {
      return <Component {...this.props} />
    }

    render() {
      const token = localStorage.getItem("token")
      let decodedToken = null
      if (token) {
        decodedToken = jwt_decode(token)
      } else {
        return <Redirect to="/login" />
      }

      if (decodedToken) {
        if (decodedToken.exp < (new Date().getTime() + 1) / 1000) {
          return <Redirect to="/login" />
        }
        if (roleId.includes(decodedToken.role)) {
          return this.renderPage()
        }
        return <Redirect to="/dashboard" />
      } else {
        return <Redirect to="/login" />
      }
    }
  }
}
