import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import React from "react"
import AppBar from "@material-ui/core/AppBar"
import { mdiMenu } from "@mdi/js"
import Icon from "@mdi/react"
import styled from "styled-components"
import { withTheme } from "@material-ui/styles"

type Props = {
  open: boolean
  handleDrawerClose: () => void
  handleDrawerOpen: () => void
}

const StyledAppBar = withTheme(styled(AppBar)`
  background-color: transparent;
  display: none;
  z-index: ${props => props.theme.zIndex.drawer + 1};
  transition: ${props =>
    props.theme.transitions.create(["width", "margin"], {
      easing: props.theme.transitions.easing.sharp,
      duration: props.theme.transitions.duration.leavingScreen,
    })};
  ${props => props.theme.breakpoints.only("xs")} {
    background-color: white;
  }
`)

const StyledToolbar = styled(Toolbar)`
  padding-right: 24px;
`

const MenuButton = withTheme(styled(IconButton)`
  color: ${props => props.theme.palette.primary.main};
  margin-right: ${props => props.theme.spacing(2)}px;
  ${props => props.theme.breakpoints.up("sm")} {
    display: none;
  }
`)

export const AppBarComponent: React.ComponentType<Props> = ({
  handleDrawerOpen,
}) => {
  return (
    <StyledAppBar elevation={0} position={"absolute"}>
      <StyledToolbar>
        <MenuButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerOpen}
        >
          <Icon path={mdiMenu} size={1} />
        </MenuButton>
      </StyledToolbar>
    </StyledAppBar>
  )
}
