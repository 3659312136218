import { createSlice } from "@reduxjs/toolkit"

import { objectReducerGenerator } from "../objectReducerGenerator"
import { storeClear } from "../actions"
import { Role, User } from "../../types/User"

export interface UserState {
  initialized: boolean
  user: User
}

const initialState: UserState = {
  initialized: false,
  user: {
    id: 0,
    name: "",
    lastName: "",
    email: "",
    uuid: "",
    role: {} as Role,
    doctor: undefined,
    activeMembership: null,
    payments: [],
  },
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    ...objectReducerGenerator<UserState>(),
  },
  extraReducers: builder => {
    builder.addCase(storeClear, () => initialState)
  },
})
