import { gql } from "@apollo/client"

const GET_ALL_USERS = gql`
  query getAllUsers($page: Int, $email: String) {
    getAllUsers(input: { page: $page, email: $email }) {
      limit
      count
      items {
        uuid
        id
        name
        lastName
        email
      }
    }
  }
`

export default GET_ALL_USERS
