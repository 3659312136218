import React from "react";
import styled from "styled-components";

type Props = {
  title: string;
  backgroundColor: string;
  textColor: string;
};

const Wrapper = styled((props) => <div {...props} />)`
  background-color: ${(props) => props.background};
  text-align: center;
  padding: 8px 16px;
  color: ${(props) => props.text};
  font-weight: 500;
  font-size: 13px;
  border-radius: 2px;
  display: inline;
`;

export const ChipComponent: React.ComponentType<Props> = ({
  title,
  backgroundColor,
  textColor,
}) => {
  return (
    <Wrapper background={backgroundColor} text={textColor}>
      {title}
    </Wrapper>
  );
};
