import React, { useState, useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "../../store/hooks"
import { useParams } from "react-router"
import { client } from "../../utilities/Apollo"
import CircularProgress from "@material-ui/core/CircularProgress"
import { AnimalList } from "../atoms/AnimalList"
import { ApolloError, FetchResult } from "@apollo/client"
import { Question } from "../../types/Question"
import GET_QUESTION from "../../query/Question/getQuestion"
import { QuestionChat } from "./QuestionChat"
import { io } from "socket.io-client"
import { AnswerT } from "../../types/Answer"
import CLOSE_QUESTION from "../../query/Question/closeQuestion"
import { LeftSidebar } from "../molecules/LeftSidebar"
import { Modal } from "../molecules/Modal"
import { LoaderButton } from "../atoms/LoaderButton"
import { colors } from "../../utilities/variables"
import { modalSlice } from "../../store/slices/modal"
import REPORT_QUESTION from "../../query/Question/reportQuestion"
import SET_CHAT_IN_PROGRESS from "../../query/Question/setChatInProgress"

const socket = io(process.env.REACT_APP_SOCKET_URL || "", {
  reconnectionDelayMax: 10000,
})

type Props = {}

type Params = {
  uuid: string
}

export const DoctorChatScreen: React.ComponentType<Props> = () => {
  const { user } = useSelector(state => state.user)
  socket.emit("subscribe", { room: user.id })
  const [loading, setLoading] = useState(false)
  const [loadingQuestion, setLoadingQuestion] = useState(true)
  const [selectedQuestion, setSelectedQuestion] = useState<Question | null>(
    null
  )
  const [answers, setAnswers] = useState<AnswerT[] | []>([])
  const questionRef = React.useRef(selectedQuestion)
  React.useEffect(() => {
    // This effect executes on every render (no dependency array specified).
    // Any change to the "participants" state will trigger a re-render
    // which will then cause this effect to capture the current "participants"
    // value in "participantsRef.current".
    questionRef.current = selectedQuestion
  })

  const params = useParams() as Params
  const dispatch = useDispatch()

  const closeQuestion = async () => {
    setLoading(true)
    await client
      .mutate({
        mutation: CLOSE_QUESTION,
        variables: { question: selectedQuestion?.id },
      })
      .then(response => onCloseQuestionSuccess(response))
      .catch(error => console.log(error))
  }

  const reportQuestion = async () => {
    setLoading(true)
    await client
      .mutate({
        mutation: REPORT_QUESTION,
        variables: { question: selectedQuestion?.id },
      })
      .then(response => onReportQuestionSuccess(response))
      .catch(error => console.log(error))
  }

  const onCloseQuestionSuccess = (response: FetchResult) => {
    setLoading(false)
    setSelectedQuestion({
      ...selectedQuestion,
      status: response?.data?.closeQuestion.status,
    } as Question)
    handleModal(false, "closeQuestion")
  }

  const setChatInProgress = async () => {
    await client
      .mutate({
        mutation: SET_CHAT_IN_PROGRESS,
        variables: {
          question: selectedQuestion?.id,
        },
      })
      .then(() => onChatInProgressSuccess())
      .catch((error: ApolloError) => console.log(error))
  }

  const onChatInProgressSuccess = () => {
    setSelectedQuestion({
      ...selectedQuestion,
      status: "CHAT_IN_PROGRESS",
    } as Question)
  }

  const onReportQuestionSuccess = (response: FetchResult) => {
    handleModal(false, "reportQuestion")
    setSelectedQuestion({
      ...selectedQuestion,
      status: "REPORTED",
    } as Question)
  }

  const getQuestion = useCallback(async uuid => {
    setLoadingQuestion(true)
    await client
      .query({ query: GET_QUESTION, variables: { id: uuid } })
      .then(response => onQuestionSuccess(response))
      .catch(error => onDataError(error))
  }, [])

  const onQuestionSuccess = (response: FetchResult) => {
    setSelectedQuestion(response?.data?.getQuestion)
    setAnswers(response?.data?.getQuestion.answers)
    setLoadingQuestion(false)
  }

  const onDataError = (error: ApolloError) => {
    console.log(error)
  }

  useEffect(() => {
    async function fetchData() {
      if (user) {
        await getQuestion(params.uuid)
      }
    }
    fetchData()
  }, [user, getQuestion, params.uuid])
  useEffect(() => {
    socket.on("new message", (data: any) => {
      if (questionRef.current && questionRef.current.id === data.question) {
        setAnswers(prevMsg => [...prevMsg, data] as [AnswerT])
      }
    }) /*
      setQuestions(prevQuestions => {
        const newQuestions = prevQuestions.map(question => {
          return question.id === data.question
            ? ({
                ...question,
                answerQuestion: [
                  {
                    id: data.id,
                    answer: data.answer,
                  },
                ],
              } as Question)
            : question
        })
        return newQuestions
      })
    })*/
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleModal = (status: boolean, modal: string) => {
    dispatch(
      modalSlice.actions.update({
        [modal]: status,
      })
    )
  }

  return (
    <div className="">
      <div className="grid grid-cols-4">
        <LeftSidebar socket={socket} />

        <div className={"h-full col-span-2"}>
          {loadingQuestion && (
            <div className={"text-center mt-3"}>
              <CircularProgress />
            </div>
          )}
          {!loadingQuestion && (
            <QuestionChat
              answers={answers}
              question={selectedQuestion}
              setChatInProgress={setChatInProgress}
            />
          )}
        </div>
        <div className="px-4">
          {loadingQuestion && (
            <div className={"text-center"}>
              <CircularProgress />
            </div>
          )}
          {!loadingQuestion && (
            <AnimalList
              animal={selectedQuestion?.animal}
              question={selectedQuestion}
              closeQuestion={() => handleModal(true, "closeQuestion")}
              reportQuestion={() => handleModal(true, "reportQuestion")}
            />
          )}
        </div>
      </div>
      <Modal modalId={"closeQuestion"} title={"Ukončit chat"}>
        <div className={"mb-4 sm:pr-20"}>
          Opravdu chcete ukončit tento chat?
        </div>
        <div className={"flex gap-4"}>
          <LoaderButton
            defineHeight={true}
            borderRadius={6}
            onClick={closeQuestion}
            loading={loading}
            title={"Ano, uzavřít"}
            backgroundColor={colors.primaryGreen}
          />
        </div>
      </Modal>
      <Modal modalId={"reportQuestion"} title={"Nahlásit dotaz"}>
        <div className={"mb-4 sm:pr-20"}>
          Opravdu chcete tento dotaz nahlásit?
        </div>
        <div className={"flex gap-4"}>
          <LoaderButton
            defineHeight={true}
            borderRadius={6}
            onClick={reportQuestion}
            loading={loading}
            title={"Ano, nahlásit"}
            backgroundColor={colors.error}
          />
        </div>
      </Modal>
    </div>
  )
}
