import { gql } from "@apollo/client"

const GET_TRANSFER_DOCTORS = gql`
  query ($id: Int!) {
    getTransferDoctors(id: $id) {
      uuid
      id
      degree
      name
      officeName
      description
      image
      street
      city
      zip
      active
      user {
        id
        email
      }
    }
  }
`

export default GET_TRANSFER_DOCTORS
