import React, { useState, useEffect, useCallback } from "react"
import Paper from "@material-ui/core/Paper"
import { client } from "../../utilities/Apollo"
import { Loader } from "../../component/atoms/Loader"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Button from "@material-ui/core/Button"
import { Wrapper } from "../../component/organisms/Wrapper"
import { NotificationDialog } from "../../component/dialogs/NotificationDialog"
import { Notification } from "../../types/Notification"
import { ApolloError, FetchResult } from "@apollo/client"
import { GET_NOTIFICATIONS } from "../../query/Notification/getNotifications"
import { format, parseISO } from "date-fns"
import styled from "styled-components"
import { NotificationDeleteDialog } from "../../component/dialogs/NotificationDeleteDialog"
import { DELETE_NOTIFICATION } from "../../query/Notification/deleteNotification"
import { useSelector } from "../../store/hooks"
import { BlueWrapper } from "../../component/atoms/BlueWrapper"
import { PageTitle } from "../../component/atoms/PageTitle"

const StyledDeleteButton = styled(Button)`
  margin-left: 1rem;
`
const StyledTableCell = styled(TableCell)`
  font-weight: 700;
`

interface DataProps {
  notifications: [Notification] | []
  openDialog: (notification: Notification) => void
  openDeleteDialog: (notification: Notification) => void
}
const DataContainer = (props: DataProps) => {
  const { notifications, openDialog, openDeleteDialog } = props
  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{"Datum odeslání"}</StyledTableCell>
              <StyledTableCell>{"Text"}</StyledTableCell>
              <StyledTableCell>{"Status"}</StyledTableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notifications.map((row: Notification) => (
              <TableRow key={row.id}>
                <TableCell component="td" scope="row">
                  {format(parseISO(row.toBeSent), "dd/MM/yyyy")}
                </TableCell>
                <TableCell component="td" scope="row">
                  {row.text}
                </TableCell>
                <TableCell component="td" scope="row">
                  {row.status}
                </TableCell>
                <TableCell component="td" scope="row" align="right">
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => openDialog(row)}
                  >
                    Upravit
                  </Button>
                  <StyledDeleteButton
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={() => openDeleteDialog(row)}
                  >
                    Smazat
                  </StyledDeleteButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

type Props = {}

export const Notifications: React.ComponentType<Props> = () => {
  const { user } = useSelector(state => state.user)

  const [loading, setLoading] = useState<boolean>(true)
  const [notifications, setNotifications] = useState<[Notification] | []>([])
  const [createDrawer, setCreateDrawer] = useState(false)
  const [deleteDrawer, setDeleteDrawer] = useState(false)
  const [currentNotification, setCurrentNotification] = useState<
    Notification | undefined
  >(undefined)
  const [notificationToBeDeleted, setNotificationToBeDeleted] =
    useState<Notification | null>(null)
  const [loadingDelete, setLoadingDelete] = useState(false)

  const openDeleteDialog = (notification: Notification) => {
    setDeleteDrawer(true)
    setNotificationToBeDeleted(notification)
  }

  const openDialog = (notification: Notification) => {
    setCurrentNotification(notification)
    setCreateDrawer(true)
  }

  const closeDialog = (bool: boolean) => {
    setCreateDrawer(bool)
    setCurrentNotification(undefined)
  }

  const getNotifications = useCallback(async () => {
    setLoading(true)
    await client
      .query({ query: GET_NOTIFICATIONS })
      .then(response => onDataSuccess(response))
      .catch(error => onDataError(error))
  }, [])

  useEffect(() => {
    async function fetchData() {
      getNotifications()
    }
    fetchData()
  }, [getNotifications])

  const onDataSuccess = (response: FetchResult) => {
    setLoading(false)
    setNotifications(response?.data?.getSystemNotifications)
  }

  const onDataError = (error: ApolloError) => {
    console.log(error)
  }

  const deleteNotification = async () => {
    setLoadingDelete(true)
    await client
      .mutate({
        mutation: DELETE_NOTIFICATION,
        variables: { id: notificationToBeDeleted?.id },
      })
      .then(response => onDeleteNotificationSuccess(response))
      .catch(error => onDeleteError(error))
  }

  const onDeleteNotificationSuccess = async (response: FetchResult) => {
    setLoadingDelete(false)
    setDeleteDrawer(false)
    getNotifications()
  }

  const onDeleteError = (error: ApolloError) => {
    setLoadingDelete(false)
    console.log(error)
  }

  return (
    <Wrapper user={user}>
      <BlueWrapper>
        <PageTitle
          title={"Notifikace"}
          addNewLink="#"
          addNewTitle="Přidat notifikaci"
          onClick={() => setCreateDrawer(true)}
        />

        {loading && <Loader />}
        {!loading && (
          <DataContainer
            notifications={notifications}
            openDialog={openDialog}
            openDeleteDialog={openDeleteDialog}
          />
        )}

        {!loading && (
          <NotificationDialog
            setCreateDrawer={closeDialog}
            createDrawer={createDrawer}
            getNotifications={getNotifications}
            notification={currentNotification}
          />
        )}
        {!loading && (
          <NotificationDeleteDialog
            setDeleteDrawer={bool => setDeleteDrawer(bool)}
            deleteDrawer={deleteDrawer}
            loadingDelete={loadingDelete}
            deleteNotification={deleteNotification}
          />
        )}
      </BlueWrapper>
    </Wrapper>
  )
}
