import * as React from "react"
import { styled, alpha } from "@mui/material/styles"
import Menu, { MenuProps } from "@mui/material/Menu"
import { FilterButton } from "../atoms/FilterButton"
import { FilterItem } from "../atoms/FilterItem"
import { LoaderButton } from "../atoms/LoaderButton"
import { Doctor } from "../../types/Doctor"

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 260,
    border: "none",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}))

const FooterButtons = styled(props => <div {...props} />)(({ theme }) => ({
  boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.03)",
  padding: "8px 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}))

type Props = {
  doctors: [Doctor] | []
  apply: (doctor: number) => void
  clear: () => void
}
export const FilterDoctors: React.ComponentType<Props> = ({
  doctors,
  apply,
  clear,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [selectedDoctor, setSelectedDoctor] = React.useState<number | null>()
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOnClick = (id: number) => {
    if (selectedDoctor === id) {
      setSelectedDoctor(null)
    } else {
      setSelectedDoctor(id)
    }
  }

  const applyFilters = () => {
    handleClose()
    apply(selectedDoctor as number)
  }

  const clearFilters = () => {
    handleClose()
    setSelectedDoctor(null)
    clear()
  }

  return (
    <div>
      <FilterButton
        onClick={handleClick}
        title={"Doktor"}
        doctors={doctors}
        count={selectedDoctor as number}
      />

      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div className="p-4">
          {doctors.map(doctor => {
            return (
              <FilterItem
                id={doctor.id}
                key={doctor.id}
                title={doctor.degree + " " + doctor.name}
                onClick={handleOnClick}
                isSelected={selectedDoctor === doctor.id}
              />
            )
          })}
        </div>
        <FooterButtons>
          <div>
            <span
              onClick={clearFilters}
              className="font-medium text-sm cursor-pointer"
            >
              Reset
            </span>
          </div>
          <div>
            <LoaderButton
              defineHeight={false}
              loading={false}
              onClick={applyFilters}
              title={"Filtrovat"}
              primary={true}
            />
          </div>
        </FooterButtons>
      </StyledMenu>
    </div>
  )
}
