import React, { useState, useEffect, useCallback, useRef } from "react"
import { useHistory, useParams } from "react-router-dom"
import Typography from "@material-ui/core/Typography"
import { client } from "../../utilities/Apollo"
import { Loader } from "../../component/atoms/Loader"
import GET_QUESTION from "../../query/Question/getQuestion"
import { AnimalList } from "../../component/atoms/AnimalList"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Icon from "@mdi/react"
import { mdiArrowLeft } from "@mdi/js"
import AppBar from "@material-ui/core/AppBar"
import { StatusChip } from "../../component/atoms/StatusChip"
import { LoaderButton } from "../../component/atoms/LoaderButton"
import APPROVE_QUESTION from "../../query/Question/approveQuestion"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import TRANSFER_QUESTION from "../../query/Question/transferQuestion"
import { Wrapper } from "../../component/organisms/Wrapper"
import { Question } from "../../types/Question"
import { ApolloError, FetchResult } from "@apollo/client"
import { useSelector } from "../../store/hooks"
import { Doctor } from "../../types/Doctor"
import { User } from "../../types/User"
import styled from "styled-components"
import { withTheme } from "@material-ui/styles"
import { AnswerT } from "../../types/Answer"
import { Answer } from "../../component/atoms/Answer"
import { BlueWrapper } from "../../component/atoms/BlueWrapper"
import { format, parseISO } from "date-fns"
import GET_TRANSFER_DOCTORS from "../../query/Doctor/getTransferDoctors"
import Rating from "@material-ui/lab/Rating"
import Box from "@material-ui/core/Box"

const StyledAppBar = styled(AppBar)`
  background-color: transparent;
`

const Heading = withTheme(styled(Typography)`
  color: black;
  margin-left: ${props => props.theme.spacing(2)}px;
  margin-right: ${props => props.theme.spacing(2)}px;
`)

const Chat = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`

const ChatList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const ChatWrapper = styled.div`
  height: 75vh;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledRating = styled(Box)`
  background-color: #e4f0fa;
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid #b1c7da;
`

interface DataProps {
  question: Question
  doctors: [Doctor] | []
  getQuestion: () => void
  user: User
  setAnswers: (answer: [AnswerT]) => void
  answers: AnswerT[]
  messageRef: any
}

const DataContainer = (props: DataProps) => {
  const { question, doctors, getQuestion, user } = props
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [doctor, setDoctor] = useState("")

  const approveQuestion = async () => {
    setLoading(true)
    await client
      .mutate({
        mutation: APPROVE_QUESTION,
        variables: { question: question.id },
      })
      .then(() => onApproveQuestionSuccess())
      .catch(error => onCreateError(error))
  }

  const transferQuestion = async () => {
    if (!doctor) {
      return null
    }
    setLoading(true)
    await client
      .mutate({
        mutation: TRANSFER_QUESTION,
        variables: { question: question.id, doctor: doctor },
      })
      .then(() => onApproveQuestionSuccess())
      .catch(error => onCreateError(error))
  }

  const onApproveQuestionSuccess = () => {
    setLoading(false)
    getQuestion()
  }

  const onCreateError = (error: ApolloError) => {
    setLoading(false)
    console.log(error)
  }

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef<HTMLDivElement>(null)
    useEffect(() => elementRef?.current?.scrollIntoView())
    return <div ref={elementRef} />
  }

  const renderAnswer = (question: Question) => {
    return (
      <BlueWrapper background={"bg-grey-chat"}>
        <ChatWrapper ref={props.messageRef}>
          <Chat>
            <ChatList>
              <div className="text-center mb-5 text-xs font-semibold text-grey-primary">
                {format(parseISO(question.answers[0].createdAt), "d.M.Y")}
              </div>
              {question.answers.map(answer => {
                return (
                  <Answer
                    key={answer.id}
                    answer={answer}
                    question={question}
                    text={answer.answer}
                    createdAt={answer.createdAt}
                  />
                )
              })}
              <AlwaysScrollToBottom />
              {(question?.status === "NEEDS_FEEDBACK" ||
                question?.status === "CLOSED") && (
                <StyledRating mb={3} borderColor="transparent">
                  <Typography component="legend">
                    {question?.status === "NEEDS_FEEDBACK"
                      ? "Čeká se na hodnocení dotazu uživatelem"
                      : "Udělené hodnocení"}
                  </Typography>
                  <Rating value={question.rating} readOnly />
                </StyledRating>
              )}
            </ChatList>
          </Chat>
        </ChatWrapper>
      </BlueWrapper>
    )
  }

  const handleChange = (event: any) => {
    setDoctor(event.target.value)
  }

  return (
    <div>
      <StyledAppBar elevation={0} position="static">
        <Toolbar disableGutters>
          <IconButton
            component="span"
            onClick={() => history.push("/questions")}
          >
            <Icon path={mdiArrowLeft} size={1} />
          </IconButton>
          <Heading variant="h4" component="h2">
            <span>Dotaz</span>
          </Heading>
          <StatusChip status={question.status} />
        </Toolbar>
      </StyledAppBar>
      <div className="grid grid-cols-3">
        <div className="col-span-2">{renderAnswer(question)}</div>
        <div>
          <AnimalList animal={question.animal} question={question} />
          <div className="px-2">
            <div className="text-grey-primary">Přiřazený veterinární lékař</div>
            <div className="font-semibold text-lg">
              {question.doctor.degree + " " + question.doctor.name}
            </div>
          </div>
          {(user.role.id === 4 || user.role.id === 1) && (
            <div className="px-2 mt-2 mb-4">
              <FormControl variant="outlined" fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  className={"px-2 bg-white"}
                >
                  Předat dotaz jinému lékaři
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={doctor}
                  autoWidth
                  onChange={handleChange}
                >
                  {doctors.map((d: Doctor, index: number) => {
                    return (
                      <MenuItem key={index} value={d.id}>
                        {d.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <LoaderButton
                borderRadius={6}
                primary={true}
                onClick={() => transferQuestion()}
                loading={loading}
                title={"Předat lékaři"}
              />
            </div>
          )}

          {question.status === "CREATED" && (
            <div className="px-2 mt-2">
              <LoaderButton
                borderRadius={6}
                primary={true}
                onClick={() => approveQuestion()}
                loading={loading}
                title={" Schválit a předat lékaři"}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

type Props = {}

type QuestionParams = {
  uuid: string
}
export const QuestionDetail: React.ComponentType<Props> = () => {
  const { user } = useSelector(state => state.user)

  let { uuid } = useParams<QuestionParams>()
  const [loading, setLoading] = useState(true)
  const [question, setQuestion] = useState<Question | null>(null)
  const [doctors, setDoctors] = useState<[Doctor] | []>([])
  const [answers, setAnswers] = useState<[AnswerT] | []>([])
  const messageEl = useRef<HTMLElement>(null)

  const getQuestion = useCallback(async () => {
    setLoading(true)
    await client
      .query({ query: GET_QUESTION, variables: { id: uuid } })
      .then(response => onDataSuccess(response))
      .catch(error => onDataError(error))
  }, [uuid]) // eslint-disable-line react-hooks/exhaustive-deps

  const getDoctors = useCallback(async (id: number) => {
    setLoading(true)
    await client
      .query({ query: GET_TRANSFER_DOCTORS, variables: { id: id } })
      .then(response => onDoctorSuccess(response))
      .catch(error => onDataError(error))
  }, [])

  useEffect(() => {
    async function fetchData() {
      getQuestion()
    }
    fetchData()
  }, [getQuestion])

  const onDoctorSuccess = (response: FetchResult) => {
    setDoctors(response?.data?.getTransferDoctors)
    setLoading(false)
  }

  const onDataSuccess = async (response: FetchResult) => {
    setQuestion(response?.data?.getQuestion)
    setAnswers(response?.data?.getQuestion.answerQuestion)

    await getDoctors(response?.data?.getQuestion.doctor.id)
  }

  const onDataError = (error: ApolloError) => {
    console.log(error)
  }

  return (
    <Wrapper user={user}>
      {loading && <Loader />}
      {!loading && question && (
        <DataContainer
          user={user}
          question={question}
          getQuestion={getQuestion}
          doctors={doctors}
          setAnswers={setAnswers}
          answers={answers}
          messageRef={messageEl}
        />
      )}
    </Wrapper>
  )
}
