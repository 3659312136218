import React from "react"
import { DoctorChatScreen } from "../../component/organisms/DoctorChatScreen"
import { useSelector } from "../../store/hooks"
import { Wrapper } from "../../component/organisms/Wrapper"

type Props = {}
export const Chat: React.ComponentType<Props> = () => {
  const { user } = useSelector(state => state.user)

  return (
    <Wrapper user={user}>
      <DoctorChatScreen />
    </Wrapper>
  )
}
