import Chip, { ChipProps } from "@material-ui/core/Chip"
import React from "react"
import styled from "styled-components"

export interface InnerChipProps {
  background: string
}

const StyledChip = styled((props: InnerChipProps & ChipProps) => (
  <Chip {...props} />
))`
  color: white;
  background-color: ${props => props.background};
`

type Props = {
  status: string
}
export const StatusChip: React.ComponentType<Props> = ({ status }) => {
  let label
  let background

  switch (status) {
    case "REPORTED":
      label = "Zpracovává Buddy"
      background = "#FF9800"
      break
    case "NEEDS_FEEDBACK":
      label = "Čeká na hodnocení"
      background = "#32638B"
      break
    case "CHAT_IN_PROGRESS":
      label = "Probíhá chat"
      background = "#FF9800"
      break
    case "ANSWERED":
      label = "Zodpovězeno"
      background = "#6A9A10"
      break
    case "ACTIVE":
      label = "Aktivní"
      background = "#6A9A10"
      break
    case "CLOSED":
      label = "Ukončen"
      background = "#6A9A10"
      break
    case "APPROVED":
      label = "Nový"
      background = "#32638B"
      break
    case "CREATED":
    default:
      label = "Čeká na schválení"
      background = "#FF3E3B"
      break
  }

  return (
    <StyledChip label={label} background={background} className={background} />
  )
}
