import React from "react"
import { Modal } from "./Modal"
import { useDispatch } from "../../store/hooks"
import { modalSlice } from "../../store/slices/modal"
import { client } from "../../utilities/Apollo"
import { ApolloError, FetchResult } from "@apollo/client"
import ReactCrop from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
import Resizer from "react-image-file-resizer"
import { Icon } from "../atoms/Icon"
import styled from "styled-components"
import { colors } from "../../utilities/variables"
import { LoaderButton } from "../atoms/LoaderButton"
import { Doctor } from "../../types/Doctor"
import UPLOAD_IMAGE from "../../query/Doctor/uploadImage"

type Props = {
  user: Doctor | null
  setImage?: (image: string) => void
}

const UserImage = styled.img`
  border-radius: 50%;
  max-width: 80px;
`

const UserAvatar = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  outline: 5px solid rgba(255, 255, 255, 0.25);
  background-color: ${colors.grey};
  display: flex;
  justify-content: center;
  align-items: center;
`

interface Crop2 {
  aspect?: number
  x?: number
  y?: number
  width?: number
  height?: number
  unit: "px" | "%"
}

interface CompletedCrop {
  x: number
  y: number
  width: number
  height: number
  unit: string
  aspect: number
}

export const UploadUserProfileImage: React.ComponentType<Props> = ({
  user,
  setImage,
}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = React.useState(false)
  const [userImage, setUserImage] = React.useState<string | null>()

  React.useEffect(() => {
    setUserImage(user?.image)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const uploadImage = async (image: string) => {
    if (setImage) {
      setImage(image)
      handleModalClose()
      setLoading(false)
      return
    }
    await client
      .mutate({
        mutation: UPLOAD_IMAGE,
        variables: {
          image: image,
          doctor: user?.id,
        },
      })
      .then(response => onUploadSuccess(response))
      .catch(error => onActionError(error))
  }

  const onUploadSuccess = async (response: FetchResult) => {
    setUserImage(response?.data?.uploadImage.image)
    handleModalClose()
    setLoading(false)
  }

  const onActionError = (error: ApolloError) => {
    setLoading(false)
  }

  const handleModalClose = () => {
    dispatch(
      modalSlice.actions.update({
        uploadAvatar: false,
      })
    )
  }
  const imgRef = React.useRef<HTMLImageElement>()
  const [completedCrop, setCompletedCrop] = React.useState<CompletedCrop>()
  const previewCanvasRef = React.useRef<HTMLCanvasElement>(null)
  const [upImg, setUpImg] = React.useState<FileReader["result"]>()
  const [crop, setCrop] = React.useState<Crop2>({
    unit: "%",
    width: 100,
    aspect: 1 / 1,
  })
  React.useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return
    }

    const image = imgRef.current
    const canvas = previewCanvasRef.current

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D
    const pixelRatio = window.devicePixelRatio

    const width = crop.width || 0
    const height = crop.height || 0
    canvas.width = width * pixelRatio
    canvas.height = height * pixelRatio

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = "high"

    const cropC = crop as CompletedCrop

    ctx.drawImage(
      image,
      cropC.x * scaleX,
      cropC.y * scaleY,
      cropC.width * scaleX,
      cropC.height * scaleY,
      0,
      0,
      cropC.width,
      cropC.height
    )
  }, [crop, completedCrop])

  const onLoad = React.useCallback(img => {
    imgRef.current = img
  }, [])

  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener("load", () =>
        openModalAndSetImage(reader.result as string)
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const openModalAndSetImage = (image: string) => {
    setUpImg(image)
    dispatch(
      modalSlice.actions.update({
        uploadAvatar: true,
      })
    )
  }

  const getGenerateDownload = () => {
    const canvas = previewCanvasRef.current as HTMLCanvasElement
    const cropObject = completedCrop as CompletedCrop
    return generateDownload(canvas, cropObject)
  }

  type TgenerateDownload = (
    canvas: HTMLCanvasElement,
    crop: CompletedCrop
  ) => void
  const generateDownload: TgenerateDownload = (canvas, crop) => {
    if (!crop || !canvas) {
      return
    }
    setLoading(true)
    canvas.toBlob(
      blob => {
        if (blob) {
          const file = new File([blob as BlobPart], "image.jpeg", {
            type: "image/jpeg",
          })
          try {
            Resizer.imageFileResizer(
              file,
              100,
              100,
              "JPEG",
              100,
              0,
              uri => {
                uploadImage(uri as string)
              },
              "base64"
            )
          } catch (err) {
            console.log(err)
          }
        }
      },
      "image/jpeg",
      1
    )
  }

  return (
    <>
      <div className="flex items-center gap-5">
        {userImage && <UserImage src={userImage} alt={user?.name} />}
        {!userImage && (
          <UserAvatar>
            <Icon icon={"grid"} color={"text-white"} size={"text-4xl"} />
          </UserAvatar>
        )}
        <label className="text-primary-blue border px-3 py-1 text-sm font-medium border-primary-blue rounded-sm cursor-pointer">
          Nahrát obrázek
          <input
            type="file"
            accept="image/*"
            onChange={onSelectFile}
            className="hidden"
          />
        </label>
      </div>
      <Modal modalId={"uploadAvatar"} title={"Nahrát profilový obrázek"}>
        <div className={"mb-4"}>
          <ReactCrop
            src={upImg as string}
            onImageLoaded={onLoad}
            crop={crop}
            minHeight={100}
            minWidth={100}
            maxHeight={400}
            maxWidth={400}
            onChange={c => setCrop(c)}
            onComplete={c => setCompletedCrop(c as CompletedCrop)}
          />
          <canvas
            className="hidden"
            ref={previewCanvasRef}
            // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
            style={{
              width: Math.round(completedCrop?.width ?? 0),
              height: Math.round(completedCrop?.height ?? 0),
            }}
          />
        </div>
        <div className={"w-full"}>
          <LoaderButton
            primary={true}
            loading={loading}
            title={"Nahrát profilový obrázek"}
            onClick={getGenerateDownload}
          />
        </div>
      </Modal>
    </>
  )
}
