import { gql } from "@apollo/client"

const GET_DOCTOR = gql`
  query ($uuid: String!) {
    getDoctor(uuid: $uuid) {
      uuid
      id
      degree
      name
      officeName
      description
      image
      street
      city
      zip
      active
      user {
        id
        email
      }
    }
  }
`

export default GET_DOCTOR
