import { gql } from "@apollo/client"

const CREATE_DOCTOR = gql`
  mutation createDoctor(
    $email: String!
    $password: String!
    $name: String!
    $street: String!
    $city: String!
    $zip: String!
    $officeName: String!
    $degree: String!
    $description: String
    $image: String
    $active: Boolean
  ) {
    createDoctor(
      input: {
        email: $email
        password: $password
        name: $name
        street: $street
        city: $city
        zip: $zip
        officeName: $officeName
        description: $description
        degree: $degree
        image: $image
        active: $active
      }
    ) {
      id
      uuid
    }
  }
`

export default CREATE_DOCTOR
