import { gql } from "@apollo/client"

const GET_QUESTION = gql`
  query getQuestion($id: String!) {
    getQuestion(uuid: $id) {
      uuid
      id
      createdAt
      status
      rating
      isPaid
      animal {
        name
        weight
        birthday
        gender
        image
        castrated
        chronicIssues
        onMedication
        animalKind {
          id
          kind
        }
        animalRace {
          race
        }
      }
      user {
        id
        email
      }
      doctor {
        id
        name
        degree
        user {
          id
        }
      }
      answers {
        id
        answer
        createdAt
        type
        media
        owner {
          id
        }
      }
    }
  }
`

export default GET_QUESTION
