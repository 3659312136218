import { IconsId } from "../../assets/fonts/iconfont/icons"
import { Icon } from "./Icon"

export interface IconProps {
  title: string
  icon: IconsId
}

export const AnimalListItem: React.FC<IconProps> = ({ title, icon }) => {
  return (
    <div className="flex items-center">
      <div className="w-8 h-8 flex items-center">
        <Icon icon={icon} color={"text-primary-blue"} />
      </div>
      <div className="text-primary-blue font-extrabold">{title}</div>
    </div>
  )
}
