import { gql } from "@apollo/client"

export const GET_NOTIFICATIONS = gql`
  query {
    getSystemNotifications {
      id
      text
      toBeSent
      createdAt
      updatedAt
      status
      receivers
    }
  }
`
