import { ApolloError, FetchResult } from "@apollo/client"
import { CircularProgress } from "@material-ui/core"
import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router"
import { BlueWrapper } from "../../component/atoms/BlueWrapper"
import { DoctorFormUpdate } from "../../component/molecules/DoctorFormUpdate"
import { Wrapper } from "../../component/organisms/Wrapper"
import GET_DOCTOR from "../../query/Doctor/getDoctor"
import { useSelector } from "../../store/hooks"
import { Doctor } from "../../types/Doctor"
import { client } from "../../utilities/Apollo"

type Props = {}

type Params = {
  uuid: string
}

export const DoctorUpdate: React.ComponentType<Props> = () => {
  const { user } = useSelector(state => state.user)
  const [loading, setLoading] = useState(true)
  const [doctor, setDoctor] = useState<Doctor | null>()
  const params = useParams() as Params
  const getDoctor = useCallback(async () => {
    setLoading(true)
    await client
      .query({ query: GET_DOCTOR, variables: { uuid: params.uuid } })
      .then(response => onDataSuccess(response))
      .catch(error => onDataError(error))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function fetchData() {
      getDoctor()
    }
    fetchData()
  }, [getDoctor])

  const onDataSuccess = (response: FetchResult) => {
    setDoctor(response?.data?.getDoctor)
    setLoading(false)
  }

  const onDataError = (error: ApolloError) => {
    console.log(error)
  }
  return (
    <Wrapper user={user}>
      <BlueWrapper>
        {loading && (
          <div className={"text-center ml-1"}>
            <CircularProgress size={16} />
          </div>
        )}
        {!loading && doctor && <DoctorFormUpdate doctor={doctor} />}
      </BlueWrapper>
    </Wrapper>
  )
}
