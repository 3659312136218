import React from "react"
import dayjs from "dayjs"
import { Animal } from "../../types/Animal"
import { Question } from "../../types/Question"
import { LoaderButton } from "./LoaderButton"
import { AnimalAvatar } from "./AnimalAvatar"
import { format } from "date-fns"
import { AnimalListItem } from "./AnimalListItem"
import styled from "styled-components"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import { Icon } from "./Icon"
import { colors } from "../../utilities/variables"

const StyledAccordion = styled(Accordion)`
  &.Mui-expanded {
    margin: 0 !important;
  }
  &:before {
    display: none;
  }
  .MuiAccordionSummary-content {
    flex-grow: 0;
  }
  &.MuiAccordionSummary-root {
    justify-content: start;
  }
`

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0 !important;
  justify-content: start !important;
  &.Mui-expanded {
    min-height: 48px !important;
    justify-content: start;
  }
  .Mui-expanded {
    margin: 12px 0 !important;
    flex-grow: 0;
  }
`

const StyledAccordionDetails = styled(AccordionDetails)`
  padding-left: 0 !important;
  padding-right: 0 !important;
`

function getBirthday(currentYear: Date, birthday: Date) {
  const animalBirthday = dayjs(currentYear)
  let year = animalBirthday.diff(birthday, "year")

  if (year !== 0) {
    return year + getBirthdayLabel(year, "year")
  }

  let month = animalBirthday.diff(birthday, "month")
  if (month !== 0) {
    return month + getBirthdayLabel(year, "month")
  }

  let day = animalBirthday.diff(birthday, "day")

  return day + getBirthdayLabel(day, "day")
}

function getBirthdayLabel(amount: number, type: string) {
  switch (type) {
    case "year":
      if (amount === 1) {
        return " rok"
      }
      if (amount > 1 && amount < 5) {
        return " roky"
      }
      return " let"
    case "month":
      if (amount === 1) {
        return " měsíc"
      }
      if (amount > 1 && amount < 5) {
        return " měsíce"
      }
      return " měsíců"
    case "day":
    default:
      if (amount === 1) {
        return " den"
      }
      if (amount > 1 && amount < 5) {
        return " dny"
      }
      return " dnů"
  }
}

type Props = {
  animal: Animal | undefined
  question: Question | null
  closeQuestion?: (id: number) => void
  reportQuestion?: (id: number) => void
}

export const AnimalList: React.FC<Props> = ({
  animal,
  question,
  closeQuestion,
  reportQuestion,
}) => {
  const currentDate = new Date()

  if (!animal) {
    return null
  }

  const initiateCloseQuestion = () => {
    if (closeQuestion) {
      closeQuestion(question?.id as number)
    }
  }

  const initiateReportQuestion = () => {
    if (reportQuestion) {
      reportQuestion(question?.id as number)
    }
  }

  const renderManagementButtons = () => {
    return (
      <>
        {(question?.status === "CHAT_IN_PROGRESS" ||
          question?.status === "APPROVED") && (
          <>
            <LoaderButton
              borderRadius={5}
              primary={true}
              title={"Ukončit chat"}
              loading={false}
              onClick={() => initiateCloseQuestion()}
            />
            <LoaderButton
              borderRadius={5}
              primary={true}
              backgroundColor={colors.error}
              title={"Nahlásit dotaz administrátorovi"}
              loading={false}
              onClick={() => initiateReportQuestion()}
            />
          </>
        )}
      </>
    )
  }

  return (
    <div className="px-2">
      <div className="flex items-center">
        <AnimalAvatar animal={animal} />
        <div className="pl-3">
          <p className="text-primary-blue font-extrabold text-xl">
            {animal.name}
          </p>
          <p className="text-sm text-grey-primary">
            {animal.animalRace?.race ?? "Kříženec"}
          </p>
          {question && (
            <p className="text-sm text-grey-primary mt-1">
              Dotaz položen: {format(new Date(question?.createdAt), "dd. MM")}
            </p>
          )}
        </div>
      </div>
      <StyledAccordion elevation={0}>
        <StyledAccordionSummary
          expandIcon={
            <Icon icon={"chevron-down"} color={"text-primary-blue"} />
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="flex items-center">
            <div className="text-base text-primary-blue font-extrabold mr-2">
              Detail o mazlíčkovi
            </div>
          </div>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <AnimalListItem icon={"pet"} title={animal.animalKind.kind} />
          <AnimalListItem
            icon={animal.animalKind.id === 2 ? "dog" : "pet"}
            title={animal.animalRace?.race || "Kříženec"}
          />
          {animal.birthday && (
            <AnimalListItem
              icon={"cake"}
              title={getBirthday(currentDate, animal.birthday)}
            />
          )}
          <AnimalListItem
            icon={"weight"}
            title={Math.round(animal.weight) + " kg"}
          />
          <AnimalListItem
            icon={"pet"}
            title={"Kastrován: " + (animal.castrated ? "Ano" : "Ne")}
          />
          <AnimalListItem
            icon={"pet"}
            title={
              "Má chronické potíže: " + (animal.chronicIssues ? "Ano" : "Ne")
            }
          />
          <AnimalListItem
            icon={"pet"}
            title={"Bere nějaké léky: " + (animal.onMedication ? "Ano" : "Ne")}
          />
        </StyledAccordionDetails>
      </StyledAccordion>

      {renderManagementButtons()}
    </div>
  )
}
