import React from "react"
import { AppBarComponent } from "../../component/molecules/AppBar"
import { DrawerComponent } from "../../component/molecules/Drawer"
import styled from "styled-components"
import { User } from "../../types/User"
import { Copyright } from "../atoms/Copyright"

const WrapperComponent = styled.div`
  background-color: #ffffff;
  padding-top: 20px;
  height: 100vh;
`

const Content = styled.main`
  height: 100%;
`

type Props = {
  user?: User
}
export const Wrapper: React.ComponentType<Props> = ({ children, user }) => {
  const [open, setOpen] = React.useState(false)
  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  return (
    <WrapperComponent>
      {user?.role && user.role.id === 1 && (
        <>
          <AppBarComponent
            open={open}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
          />
          <div className={"flex h-full"}>
            <div className={"sm:w-3/12 w-auto flex flex-col justify-between"}>
              <DrawerComponent
                open={open}
                handleDrawerClose={handleDrawerClose}
              />
              <Copyright />
            </div>
            <div className="sm:w-9/12 pb-8 w-full h-full">{children}</div>
          </div>
        </>
      )}
      {user?.role && (user.role.id === 2 || user.role.id === 4) && (
        <Content>{children}</Content>
      )}
    </WrapperComponent>
  )
}
