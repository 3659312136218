import React from "react"
import Hidden from "@material-ui/core/Hidden"
import BuddyLogo from "../../assets/buddyvet-new-logo.svg"
import List from "@material-ui/core/List"
import Drawer from "@material-ui/core/Drawer/Drawer"
import {
  mdiBell,
  mdiViewDashboard,
  mdiDoctor,
  mdiCommentQuestion,
  mdiCog,
  mdiAccount,
} from "@mdi/js"
import { User } from "../../types/User"
import { useSelector } from "../../store/hooks"
import styled from "styled-components"
import { withTheme } from "@material-ui/styles"
import { MenuLink } from "./MenuLink"
import { LeftSidebarUser } from "../atoms/LeftSidebarUser"
import { useHistory } from "react-router"

const drawerWidth = 325

type Props = {
  open: boolean
  handleDrawerClose: () => void
  window?: () => Window
}

const Logo = styled.img`
  max-width: 199px;
`

const StyledDrawerWrapper = withTheme(styled.nav`
  ${props => props.theme.breakpoints.up("sm")} {
    width: ${drawerWidth};
    flex-shrink: 0;
    background-color: white;
  }
`)

const StyledDrawer = withTheme(styled(Drawer)`
  .MuiDrawer-paper {
    position: relative;
    white-space: nowrap;
    border: none;
    width: ${drawerWidth}px;
    transition: ${props =>
      props.theme.transitions.create(["width"], {
        easing: props.theme.transitions.easing.sharp,
        duration: props.theme.transitions.duration.enteringScreen,
      })};
  }
`)

export const DrawerComponent: React.ComponentType<Props> = ({
  open,
  handleDrawerClose,
  window,
}) => {
  const { user } = useSelector(state => state.user)
  const history = useHistory()
  const container =
    window !== undefined ? () => window().document.body : undefined

  const renderSuperDoctorRoutes = (user: User) => {
    if (user.role.id === 4 || user.role.id === 1) {
      return (
        <MenuLink
          link={"/questions"}
          label={"Dotazy"}
          icon={mdiCommentQuestion}
        />
      )
    }
  }

  const renderSuperAdminRoutes = (user: User) => {
    if (user.role.id === 1) {
      return (
        <>
          <MenuLink link={"/doctors"} label={"Doktoři"} icon={mdiDoctor} />
          <MenuLink
            link={"/configuration"}
            label={"Konfigurace"}
            icon={mdiCog}
          />
          <MenuLink
            link={"/notifications"}
            label={"Notifikace"}
            icon={mdiBell}
          />
          <MenuLink link={"/users"} label={"Uživatelé"} icon={mdiAccount} />
        </>
      )
    }
  }

  const renderDrawer = () => {
    return (
      <div className={"px-6"}>
        <div
          className="border-b-2 pt-3 mb-3 cursor-pointer"
          onClick={() => history.push("/dashboard")}
        >
          <Logo src={BuddyLogo} alt="BuddyVET logo" className="pb-3" />
        </div>
        <LeftSidebarUser />
        <List>
          <MenuLink
            link={"/dashboard"}
            label={"Dashboard"}
            icon={mdiViewDashboard}
          />
          {user.role && renderSuperAdminRoutes(user)}
          {user.role && renderSuperDoctorRoutes(user)}
        </List>
      </div>
    )
  }

  return (
    <StyledDrawerWrapper>
      <Hidden smUp implementation="css">
        <StyledDrawer
          container={container}
          variant={"temporary"}
          onClose={handleDrawerClose}
          open={open}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {renderDrawer()}
        </StyledDrawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <StyledDrawer variant="permanent" open>
          {renderDrawer()}
        </StyledDrawer>
      </Hidden>
    </StyledDrawerWrapper>
  )
}
