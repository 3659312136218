import { gql } from "@apollo/client"

const GET_CHATS_BY_STATUS = gql`
  query getChatsByStatus($doctor: Int!, $status: String!) {
    getChatsByStatus(input: { doctor: $doctor, status: $status }) {
      uuid
      id
      createdAt
      status
      isPaid
      animal {
        name
        weight
        birthday
        gender
        image
        animalKind {
          id
          kind
        }
        animalRace {
          race
        }
      }
      user {
        id
        email
      }
      lastAnswer
      lastMedia
      lastType
    }
  }
`

export default GET_CHATS_BY_STATUS
