import React from "react"
import { Doctor } from "../../types/Doctor"
import { FormTitleSubtitle } from "../atoms/FormTitleSubtitle"
import { InputField } from "../atoms/InputField"
import * as yup from "yup"
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { colors, translations } from "../../utilities/variables"
import { ApolloError, FetchResult } from "@apollo/client"
import { client } from "../../utilities/Apollo"
import { snackbarSlice } from "../../store/slices/snackbarSlice"
import { useDispatch } from "../../store/hooks"
import { UploadUserProfileImage } from "./UploadUserProfileImage"
import { LoaderButton } from "../atoms/LoaderButton"
import { useHistory } from "react-router"
import Switch, { SwitchProps } from "@mui/material/Switch"
import { styled } from "@mui/material/styles"
import FormControlLabel from "@mui/material/FormControlLabel"
import { FabButton } from "../atoms/FabButton"
import { Icon } from "../atoms/Icon"
import UPDATE_DOCTOR from "../../query/Doctor/updateDoctor"

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: colors.primaryGreen,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    border: "1px solid " + colors.grey,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}))

export interface Props {
  doctor: Doctor
}

interface IFormInput {
  degree: string
  officeName: string
  name: string
  city: string
  street: string
  zip: string
  active: boolean
  description: string
}

const schema = yup.object().shape({
  degree: yup.string().required(translations.required),
  name: yup.string().required(translations.required),
  officeName: yup.string().required(translations.required),
  city: yup.string().required(translations.required),
  street: yup.string().required(translations.required),
  zip: yup.string().required(translations.required),
})

export const DoctorFormUpdate: React.FC<Props> = ({ doctor }) => {
  const [loading, setLoading] = React.useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  })

  const onSubmit: SubmitHandler<IFormInput> = async data => {
    console.log(errors)
    trigger()

    setLoading(true)
    await client
      .mutate({
        mutation: UPDATE_DOCTOR,
        variables: { ...data, id: doctor.id },
      })
      .then(response => onSuccess(response))
      .catch(error => onDataError(error))
  }

  const onSuccess = (response: FetchResult) => {
    history.push("/doctors")
    dispatch(
      snackbarSlice.actions.put({
        open: true,
        message: "Doktor upraven",
        severity: "success",
      })
    )
    setLoading(false)
  }

  const onDataError = (error: ApolloError) => {
    console.log(error)
  }

  React.useEffect(() => {
    register("officeName", { required: true })
    register("street", { required: true })
    register("city", { required: true })
    register("zip", { required: true })
    register("degree", { required: true })
    register("name", { required: true })
    register("description")
    setValue("active", doctor.active)
    setValue("degree", doctor.degree)
    setValue("name", doctor.name)
    setValue("officeName", doctor.officeName)
    setValue("street", doctor.street)
    setValue("city", doctor.city)
    setValue("zip", doctor.zip)
  }, [register]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className="flex items-center">
        <FabButton
          onClick={() => {
            history.push("/doctors")
          }}
        >
          <Icon icon={"chevron-left"} size={"text-3xl"} />
        </FabButton>
        <h2 className="ml-3 text-3xl">Upravit doktora</h2>
      </div>

      <div className={"grid sm:grid-cols-2 grid-cols-1 gap-20 pb-3 mt-6"}>
        <div>
          <FormTitleSubtitle
            title={"Profil doktora"}
            fontWeight="font-semibold"
          />

          <form onSubmit={handleSubmit(onSubmit)} className={"flex flex-col"}>
            <div className="grid grid-cols-3 gap-5">
              <div>
                <InputField
                  label={"Titul"}
                  name="degree"
                  defaultValue={doctor.degree}
                  error={errors.degree?.message}
                  onChange={async (e: any, data: any) => {
                    setValue(e.target.name, e.target.value)
                  }}
                />
              </div>
              <div className="col-span-2">
                <InputField
                  label={"Jméno"}
                  defaultValue={doctor.name}
                  name="name"
                  error={errors.name?.message}
                  onChange={async (e: any, data: any) => {
                    setValue(e.target.name, e.target.value)
                  }}
                />
              </div>
            </div>

            <InputField
              label={"Název ordinace"}
              defaultValue={doctor.officeName}
              name="officeName"
              error={errors.officeName?.message}
              onChange={async (e: any, data: any) => {
                setValue(e.target.name, e.target.value)
              }}
            />

            <Controller
              name="active"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  label="Doktor je aktivní"
                  sx={{ fontFamily: "Nunito" }}
                  control={
                    <IOSSwitch
                      sx={{ m: 2 }}
                      onChange={onChange}
                      checked={value}
                    />
                  }
                />
              )}
            />

            <FormTitleSubtitle
              title={"Adresa ordinace"}
              fontWeight="font-semibold"
            />

            <InputField
              label={"Ulice"}
              defaultValue={doctor.street}
              name="street"
              error={errors.street?.message}
              onChange={async (e: any, data: any) => {
                setValue(e.target.name, e.target.value)
              }}
            />

            <div className="grid grid-cols-3 gap-5">
              <div className="col-span-2">
                <InputField
                  label={"Město"}
                  defaultValue={doctor.city}
                  name="city"
                  error={errors.city?.message}
                  onChange={async (e: any, data: any) => {
                    setValue(e.target.name, e.target.value)
                  }}
                />
              </div>
              <InputField
                label={"PSČ"}
                defaultValue={doctor.zip}
                name="zip"
                error={errors.zip?.message}
                onChange={async (e: any, data: any) => {
                  setValue(e.target.name, e.target.value)
                }}
              />
            </div>
          </form>
        </div>
        <div>
          <FormTitleSubtitle
            title={"Profilový obrázek"}
            fontWeight="font-semibold"
          />
          <UploadUserProfileImage user={doctor} />
        </div>
        <LoaderButton
          onClick={handleSubmit(onSubmit, data => console.log(data))}
          loading={loading}
          title={"Upravit doktora"}
          primary={true}
        />
      </div>
    </div>
  )
}
