import { gql } from "@apollo/client"

const GET_USER = gql`
  query getUser($id: String!) {
    getUser(uuid: $id) {
      uuid
      id
      name
      lastName
      email
      activeMembership {
        id
        active
        expiresAt
      }
      payments {
        id
        amount
        paymentType
        paymentPlatform
        createdAt
        name
        lastName
        street
        city
        zip
        phone
        email
      }
    }
  }
`

export default GET_USER
