import { Animal } from "../../types/Animal"
import styled from "styled-components"
import classNames from "classnames"
import { Icon } from "./Icon"

export interface IconProps {
  animal: Animal
  dimensions?: string
}

const ListAvatar = styled.div`
  grid-area: avatar;
  position: relative;
  img {
    border-radius: 50%;
  }
`

export const AnimalAvatar: React.FC<IconProps> = ({
  animal,
  dimensions = "h-16 w-16",
}) => {
  return (
    <div className={"flex items-center"}>
      {animal.image && (
        <ListAvatar>
          <img
            src={animal.image}
            alt={animal.name}
            loading="lazy"
            className={"h-16 w-16"}
          />
        </ListAvatar>
      )}
      {!animal.image && (
        <div
          className={classNames(
            "bg-gray-100 flex items-center justify-center rounded-full",
            dimensions
          )}
        >
          <Icon icon={"pet"} color={"text-primary-blue"} />
        </div>
      )}
    </div>
  )
}
