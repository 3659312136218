import React from "react"
import { useSelector } from "../../store/hooks"
import { AdminDashboard } from "../../component/organisms/AdminDashboard"
import { Wrapper } from "../../component/organisms/Wrapper"
import { LeftSidebar } from "../../component/molecules/LeftSidebar"
import { io } from "socket.io-client"

const socket = io(process.env.REACT_APP_SOCKET_URL || "", {
  reconnectionDelayMax: 10000,
})

type Props = {}
export const Dashboard: React.ComponentType<Props> = () => {
  const { user } = useSelector(state => state.user)

  return (
    <Wrapper user={user}>
      {user.role && (user.role.id === 2 || user.role.id === 4) && (
        <div className="flex h-full">
          <div className="w-4/12">
            <LeftSidebar socket={socket} />
          </div>

          <div className="bg-primary-lightBlue w-8/12 mb-8 mx-8 rounded-xl p-10 border border-border-blue">
            <h2 className="text-xl font-extrabold">
              Přehled Veterinární poradny Buddyvet
            </h2>
          </div>
        </div>
      )}
      {user.role && user.role.id === 1 && <AdminDashboard />}
    </Wrapper>
  )
}
