import { gql } from "@apollo/client"

const GET_QUESTION_FILTER = gql`
  query getQuestionFilters {
    getQuestionFilters {
      vets {
        id
        degree
        name
      }
      statuses {
        label
        value
      }
    }
  }
`

export default GET_QUESTION_FILTER
