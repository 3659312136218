import React from "react"
import styled from "styled-components"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import { colors } from "../../utilities/variables"
import { Icon } from "../atoms/Icon"
import { CircularProgress } from "@material-ui/core"
import { Question } from "../../types/Question"
import { AnimalAvatar } from "../atoms/AnimalAvatar"
import { useHistory } from "react-router"

type Props = {
  handleChange: (active: string) => any
  expanded: string | false
  status: string
  loading: boolean
  questions: Question[] | []
  title: string
  active?: string
}

const StyledAccordion = styled(Accordion)`
  &.Mui-expanded {
    margin: 0 !important;
  }
  &:before {
    display: none;
  }
`

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0 !important;
  &.Mui-expanded {
    min-height: 48px !important;
  }
  .Mui-expanded {
    margin: 12px 0 !important;
  }
`

const StyledAccordionDetails = styled(AccordionDetails)`
  padding-left: 0 !important;
  padding-right: 0 !important;
`

const StyledListItem = styled(props => <div {...props} />)`
  border-radius: 13px;
  background-color: ${colors.chatBackground};
  border: 1px solid ${colors.petBorder};
  &:hover {
    border: 1px solid ${colors.primaryBlue};
  }
  ${({ $active }) =>
    $active &&
    `
    border: 1px solid ${colors.primaryBlue};
   
  `}
`

const QuestionEllipsis = styled.span`
  white-space: nowrap;
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  color: ${colors.answer};
`

const ListItemWrapper = styled.div`
  cursor: pointer;
  padding: 0.95rem 1rem;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const QuestionAnimal = styled.span`
  grid-area: name;
  color: ${colors.primaryBlue};
  font-size: 14px;
  font-weight: 800;
`

export const ChatAccordion: React.ComponentType<Props> = ({
  handleChange,
  expanded,
  status,
  loading,
  questions,
  title,
  active,
}) => {
  const history = useHistory()
  return (
    <StyledAccordion
      expanded={expanded === status}
      onChange={handleChange(status)}
      elevation={0}
    >
      <StyledAccordionSummary
        expandIcon={
          expanded === status ? (
            <Icon icon={"minus"} size={"text-2xl"} />
          ) : (
            <Icon icon={"plus"} size={"text-base"} />
          )
        }
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <div className="flex items-center">
          <div className="text-base text-primary-blue font-extrabold">
            {title}
          </div>
          {loading && (
            <div className={"text-center ml-1"}>
              <CircularProgress size={16} />
            </div>
          )}
          {!loading && (
            <div className="text-primary-blue text-base ml-1">
              ({questions.length})
            </div>
          )}
        </div>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        {questions.map(question => {
          let subtitle = ""
          if (question.lastAnswer) {
            subtitle = `${question.lastAnswer.substring(0, 30)}...`
          }
          if (question.lastMedia !== null) {
            if (question.lastType === "image") {
              subtitle = "Příloha: Obrázek"
            } else {
              subtitle = "Příloha: Video"
            }
          }
          return (
            <div className="pb-3" key={question.id}>
              <StyledListItem
                $active={active === question.uuid}
                onClick={() => history.push("/chat/" + question.uuid)}
              >
                <ListItemWrapper>
                  <div className="flex flex-grow">
                    <AnimalAvatar
                      animal={question.animal}
                      dimensions="w-10 h-10"
                    />
                    <div className={"pl-5 flex flex-col justify-center"}>
                      <QuestionAnimal>{question.animal.name}</QuestionAnimal>
                      <QuestionEllipsis>{subtitle}</QuestionEllipsis>
                    </div>
                  </div>
                  <div>{question.isPaid && <span>Placený dotaz</span>}</div>
                </ListItemWrapper>
              </StyledListItem>
            </div>
          )
        })}
        {questions.length === 0 && <div>Nejsou zde žádné chaty</div>}
      </StyledAccordionDetails>
    </StyledAccordion>
  )
}
