import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress"
import Backdrop from "@material-ui/core/Backdrop/Backdrop"
import React from "react"
import styled from "styled-components"
import { withTheme } from "@material-ui/styles"

const StyledBackdrop = withTheme(styled(Backdrop)`
   z-index: ${props => props.theme.zIndex.drawer + 1} !important;
    color: ${props => props.theme.palette.primary.main}
    background-olor: #a9a9a970 !important;
`)

type Props = {}
export const Loader: React.FC<Props> = () => {
  return (
    <StyledBackdrop open={true}>
      <CircularProgress color="inherit" />
    </StyledBackdrop>
  )
}
