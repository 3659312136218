import React from "react";
import { colors } from "../../utilities/variables";
import styled from "styled-components";
import Fab from "@mui/material/Fab";

type Props = {
  onClick: (event?: any) => void;
  id?: string;
};

const StyledFabButton = styled(Fab)`
  background-color: ${colors.white} !important;
  box-shadow: 0px 5px 10px rgba(28, 33, 52, 0.05) !important;
`;

export const FabButton: React.ComponentType<Props> = ({
  children,
  onClick,
  id,
}) => {
  return (
    <StyledFabButton id={id} onClick={onClick}>
      {children}
    </StyledFabButton>
  );
};
