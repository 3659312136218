import React, { useEffect } from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import CssBaseline from "@material-ui/core/CssBaseline"
import { authenticatedPage } from "./authenticatedPage"
import { useHistory } from "react-router"
import { useDispatch } from "../store/hooks"
import { userSlice } from "../store/slices/userSlice"
import { client } from "../utilities/Apollo"
import GET_CURRENT_USER from "../query/User/getCurrent"
import { storeClear } from "../store/actions/storeClear"

import { Login } from "./routes/Login"
import { Dashboard } from "./routes/Dashboard"
import { Doctors } from "./routes/Doctors"
import { Configuration } from "./routes/Configuration"
import { Questions } from "./routes/Questions"
import { QuestionDetail } from "./routes/QuestionDetail"
import { SnackbarComponent } from "../component/atoms/Snackbar"
import { Notifications } from "./routes/Notifications"
import { Chat } from "./routes/Chat"
import { DoctorCreate } from "./routes/DoctorCreate"
import { DoctorUpdate } from "./routes/DoctorUpdate"
import { Users } from "./routes/Users"
import { UserDetail } from "./routes/UserDetail"

const Routing = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem("token")
      if (token) {
        const getUser = async () => {
          await client
            .query({ query: GET_CURRENT_USER })
            .then(response => {
              dispatch(
                userSlice.actions.put({
                  initialized: true,
                  user: response.data.currentAdminDoctor,
                })
              )
            })
            .catch(() => {
              localStorage.removeItem("token")
              dispatch(storeClear())
              return <Redirect to="/login" />
            })
        }
        await getUser()
      } else {
        return <Redirect to="/login" />
      }
    }
    fetchData()
  }, [history, dispatch])

  return (
    <>
      <CssBaseline />
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <Route exact path="/login" component={Login} />
        <Route
          exact
          path="/dashboard"
          component={authenticatedPage(Dashboard, [1, 2, 4])}
        />
        <Route
          exact
          path="/chat/:uuid"
          component={authenticatedPage(Chat, [1, 2, 4])}
        />
        <Route
          exact
          path="/questions"
          component={authenticatedPage(Questions, [1, 4])}
        />
        <Route
          exact
          path="/questions/:uuid"
          component={authenticatedPage(QuestionDetail, [1, 4])}
        />
        <Route
          exact
          path="/configuration"
          component={authenticatedPage(Configuration, [1])}
        />
        <Route
          exact
          path="/doctors"
          component={authenticatedPage(Doctors, [1])}
        />
        <Route
          exact
          path="/doctors/create"
          component={authenticatedPage(DoctorCreate, [1])}
        />
        <Route
          exact
          path="/doctors/update/:uuid"
          component={authenticatedPage(DoctorUpdate, [1])}
        />
        <Route
          exact
          path="/notifications"
          component={authenticatedPage(Notifications, [1])}
        />
        <Route
          exact
          path="/users/:uuid"
          component={authenticatedPage(UserDetail, [1])}
        />
        <Route exact path="/users" component={authenticatedPage(Users, [1])} />
      </Switch>
      <SnackbarComponent />
    </>
  )
}

export default Routing
