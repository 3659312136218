import React, { useCallback, useState, useEffect } from "react"
import { useSelector } from "../../store/hooks"
import { useHistory, useParams } from "react-router"
import styled from "styled-components"
import { Question } from "../../types/Question"
import { client } from "../../utilities/Apollo"
import { ApolloError, FetchResult } from "@apollo/client"
import BuddyLogo from "../../assets/buddyvet-new-logo.svg"
import { Icon } from "../atoms/Icon"
import GET_CHATS_BY_STATUS from "../../query/Question/getQuestions"
import { ChatAccordion } from "./ChatAccordion"
import { LeftSidebarUser } from "../atoms/LeftSidebarUser"

type Props = {
  socket: any
}

type Params = {
  uuid: string
}

const Logo = styled.img`
  max-width: 195px;
`

export const LeftSidebar: React.ComponentType<Props> = ({ socket }) => {
  const history = useHistory()
  const params = useParams() as Params
  const { user } = useSelector(state => state.user)
  const [loadingActive, setLoadingActive] = useState(true)
  const [loadingNew, setLoadingNew] = useState(true)
  const [loadingClosed, setLoadingClosed] = useState(true)
  const [activeQuestions, setActiveQuestions] = useState<Question[] | []>([])
  const [newQuestions, setNewQuestions] = useState<Question[] | []>([])
  const [closedQuestions, setClosedQuestions] = useState<Question[] | []>([])

  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  useEffect(() => {
    socket.on("new topic", (data: any) => {
      setNewQuestions(prevMsg => [data, ...prevMsg] as [Question])
    })
    socket.on("new message", (data: any) => {
      setActiveQuestions(prevQuestions => {
        const newQuestions = prevQuestions.map(question => {
          return question.id === data.question
            ? ({
                ...question,
                lastAnswer: data.lastAnswer,
                lastMedia: data.lastMedia,
                lastType: data.lastType,
              } as Question)
            : question
        })
        return newQuestions
      })
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getQuestions = useCallback(
    async (status: string) => {
      switch (status) {
        case "CLOSED":
          setLoadingClosed(true)
          break
        case "APPROVED":
          setLoadingNew(true)
          break
        case "CHAT_IN_PROGRESS":
        default:
          setLoadingActive(true)
          break
      }

      await client
        .query({
          query: GET_CHATS_BY_STATUS,
          variables: { doctor: user?.doctor?.id, status: status },
        })
        .then(response => onDataSuccess(response, status))
        .catch(error => onDataError(error))
    },
    [user] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const onDataSuccess = useCallback(
    async (response: FetchResult, status: string) => {
      switch (status) {
        case "CLOSED":
          const activeClosedQuestion = response?.data?.getChatsByStatus.find(
            (q: Question) => q.uuid === params.uuid
          )
          if (activeClosedQuestion) {
            setExpanded("closed")
          }
          setClosedQuestions(response?.data?.getChatsByStatus)
          setLoadingClosed(false)
          break
        case "APPROVED":
          const activeApprovedQuestion = response?.data?.getChatsByStatus.find(
            (q: Question) => q.uuid === params.uuid
          )
          if (activeApprovedQuestion) {
            setExpanded("new")
          }
          setNewQuestions(response?.data?.getChatsByStatus)
          setLoadingNew(false)
          break
        case "CHAT_IN_PROGRESS":
        default:
          const activeActiveQuestion = response?.data?.getChatsByStatus.find(
            (q: Question) => q.uuid === params.uuid
          )
          if (activeActiveQuestion) {
            setExpanded("active")
          }
          setActiveQuestions(response?.data?.getChatsByStatus)
          setLoadingActive(false)
          break
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const onDataError = (error: ApolloError) => {
    console.log(error)
  }

  useEffect(() => {
    async function fetchData() {
      if (user.id) {
        await getQuestions("CHAT_IN_PROGRESS")
        await getQuestions("APPROVED")
        await getQuestions("CLOSED")
      }
    }
    fetchData()
  }, [user, getQuestions])

  return (
    <div className="px-8">
      <div
        onClick={() => history.push("/dashboard")}
        className="cursor-pointer"
      >
        <Logo src={BuddyLogo} alt="BuddyVET logo" className="pb-4" />
      </div>
      <div
        className="border-t-2 py-6 flex items-center cursor-pointer"
        onClick={() => history.push("/dashboard")}
      >
        <Icon icon={"grid"} color={"text-primary-blue"} size={"text-2xl"} />

        <div className="ml-3 font-extrabold text-primary-blue text-base">
          Dashboard
        </div>
      </div>

      <LeftSidebarUser />
      <div className="font-extrabold text-primary-blue text-base mb-5">
        Přehled
      </div>
      <ChatAccordion
        title="Aktivní chaty"
        loading={loadingActive}
        expanded={expanded}
        status={"active"}
        handleChange={handleChange}
        questions={activeQuestions}
        active={params.uuid}
      />
      <ChatAccordion
        title="Nové chaty"
        loading={loadingNew}
        expanded={expanded}
        status={"new"}
        handleChange={handleChange}
        questions={newQuestions}
        active={params.uuid}
      />
      <ChatAccordion
        title="Ukončené chaty"
        loading={loadingClosed}
        expanded={expanded}
        status={"closed"}
        handleChange={handleChange}
        questions={closedQuestions}
        active={params.uuid}
      />
    </div>
  )
}
