import React, { useState, useEffect, useCallback } from "react"
import { client } from "../../utilities/Apollo"
import { Loader } from "../../component/atoms/Loader"
import { Wrapper } from "../../component/organisms/Wrapper"
import { ApolloError, FetchResult } from "@apollo/client"
import { useSelector } from "../../store/hooks"
import { Payment, User } from "../../types/User"
import { BlueWrapper } from "../../component/atoms/BlueWrapper"
import GET_USER from "../../query/User/getUser"
import { useHistory, useParams } from "react-router-dom"
import styled from "styled-components"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Android from "@mui/icons-material/Android"
import Apple from "@mui/icons-material/Apple"
import dayjs from "dayjs"
import { StatusChip } from "../../component/atoms/StatusChip"
import IconButton from "@material-ui/core/IconButton"
import Icon from "@mdi/react"
import { mdiArrowLeft } from "@mdi/js"
import Collapse from "@mui/material/Collapse"
import Box from "@mui/material/Box"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"

type Props = {}

type UserParams = {
  uuid: string
}

const StyledTableCell = styled(TableCell)`
  font-weight: 700 !important;
  font-family: Nunito !important;
`

const StyledTableCellFont = styled(TableCell)`
  font-family: Nunito !important;
`

type RowProps = {
  row: Payment
}
const Row: React.ComponentType<RowProps> = ({ row }) => {
  const [open, setOpen] = React.useState(false)
  const getCreatedAt = (date: Date, format: string) => {
    return dayjs(date).format(format)
  }

  return (
    <>
      <TableRow
        key={row.id}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {row.paymentPlatform === "android" ? <Android /> : <Apple />}
        </TableCell>
        <StyledTableCellFont>
          {row.paymentType === "one-time"
            ? "Jednorázová konzultace"
            : "Roční členství"}
        </StyledTableCellFont>
        <StyledTableCellFont>
          {getCreatedAt(row.createdAt, "D. MM. YYYY H:mm:ss")}
        </StyledTableCellFont>

        <StyledTableCellFont align="right">
          {row.amount}&nbsp;Kč
        </StyledTableCellFont>
      </TableRow>
      <TableRow>
        <StyledTableCellFont
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={4}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <div className="flex flex-wrap my-4">
                <div className="w-1/2">
                  <h2 className="font-bold text-xl mb-2">Fakturační údaje</h2>
                  <p>
                    {row.name} {row.lastName}
                  </p>
                  <p>{row.street}</p>
                  <p>
                    {row.zip} {row.city}
                  </p>
                </div>
                <div>
                  <h2 className="font-bold text-xl mb-2">Kontaktní údaje</h2>
                  <p>{row.email}</p>
                  <p>{row.phone}</p>
                </div>
              </div>
            </Box>
          </Collapse>
        </StyledTableCellFont>
      </TableRow>
    </>
  )
}

export const UserDetail: React.ComponentType<Props> = () => {
  const { user } = useSelector(state => state.user)

  let { uuid } = useParams<UserParams>()
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [fetchedUser, setFetchedUser] = useState<User | null>(null)

  const getUser = useCallback(async () => {
    setLoading(true)
    await client
      .query({ query: GET_USER, variables: { id: uuid } })
      .then(response => onDataSuccess(response))
      .catch(error => onDataError(error))
  }, [uuid]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function fetchData() {
      getUser()
    }
    fetchData()
  }, [getUser])

  const onDataSuccess = async (response: FetchResult) => {
    setFetchedUser(response?.data?.getUser)

    setLoading(false)
  }

  const onDataError = (error: ApolloError) => {
    console.log(error)
  }

  const getCreatedAt = (date: Date, format: string) => {
    return dayjs(date).format(format)
  }

  return (
    <Wrapper user={user}>
      {loading && <Loader />}
      {!loading && fetchedUser && (
        <BlueWrapper background={"bg-grey-chat"}>
          <div className="flex justify-between items-center mb-10">
            <div className="flex items-center">
              <IconButton
                component="span"
                onClick={() => history.push("/users")}
              >
                <Icon path={mdiArrowLeft} size={1} />
              </IconButton>
              <h1 className="text-2xl font-bold ml-5">
                {fetchedUser.name
                  ? `${fetchedUser.name} ${fetchedUser.lastName}`
                  : fetchedUser.email}
              </h1>
            </div>

            <div>ID: #{fetchedUser.id}</div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
            <div>
              <h2 className="text-lg font-bold mb-5">Platby</h2>

              {fetchedUser.payments.length > 0 && (
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell>Typ</StyledTableCell>
                        <StyledTableCell>Platba</StyledTableCell>
                        <StyledTableCell align="right">Částka</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fetchedUser.payments.map((row: Payment) => (
                        <Row row={row} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {fetchedUser.payments.length === 0 && (
                <div>Nejsou evidovány žádné platby</div>
              )}
            </div>
            <div>
              <h2 className="text-lg font-bold mb-5">Členství</h2>

              {fetchedUser.activeMembership && (
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Zakoupeno</StyledTableCell>
                        <StyledTableCell>Končí</StyledTableCell>
                        <StyledTableCell align="right">Aktivní</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        key={fetchedUser.activeMembership.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <StyledTableCellFont>
                          {getCreatedAt(
                            fetchedUser.activeMembership.createdAt,
                            "D. M. YYYY"
                          )}
                        </StyledTableCellFont>
                        <StyledTableCellFont>
                          {getCreatedAt(
                            fetchedUser.activeMembership.expiresAt,
                            "D. M. YYYY"
                          )}
                        </StyledTableCellFont>

                        <TableCell align="right">
                          <StatusChip status="ACTIVE"></StatusChip>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {!fetchedUser.activeMembership && (
                <div>Není aktivní členství</div>
              )}
            </div>
          </div>
        </BlueWrapper>
      )}
    </Wrapper>
  )
}
