import React from "react"
import styled from "styled-components"
import { colors } from "../../utilities/variables"
import { Icon } from "./Icon"
import Menu from "@mui/material/Menu"
import { useDispatch, useSelector } from "../../store/hooks"
import { storeClear } from "../../store/actions"
import { useHistory } from "react-router"

type Props = {}

const StyledDoctor = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 50%;
`

const StyledIcon = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
`

const OnlineIcon = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${colors.primaryGreen};
  border-radius: 50%;
  outline: 3px solid #fff;
`

export const LeftSidebarUser: React.FC<Props> = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.user)
  const history = useHistory()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    dispatch(storeClear())
    localStorage.removeItem("token")
    history.push("/login")
  }
  return (
    <div className={"py-3 mb-5"}>
      <div className="flex">
        <div className="relative">
          {user.doctor?.image ? (
            <StyledDoctor src={user.doctor?.image} />
          ) : (
            <StyledIcon>
              <Icon icon={"pet"} color={"text-primary"} />
            </StyledIcon>
          )}
          <div className="absolute bottom-1 right-1">
            <OnlineIcon />
          </div>
        </div>
        <div className="pl-3">
          <p className="text-lg font-extrabold text-primary-blue">
            {user.doctor !== null
              ? user.doctor?.name
              : user.name + " " + user.lastName}
          </p>
          <p className="text-xs text-grey-primary">
            {user.doctor !== null ? "Veterinární lékař" : "Admin"}
          </p>
          <p
            className="mt-1 text-xs text-primary-green flex items-center cursor-pointer"
            onClick={handleClick}
          >
            <span className="mr-1">Online</span>
            <Icon
              icon={"chevron-down"}
              color="text-grey-primary"
              size={"text-xs"}
            />
          </p>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  left: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          >
            <div className="">
              <div
                className="flex pt-1 px-5 cursor-pointer hover:bg-gray-100"
                onClick={handleLogout}
              >
                <div className="mr-4">
                  <Icon icon={"logout"} color={"text-error"} />
                </div>
                <div className="text-error font-extrabold">Odhlásit se</div>
              </div>
            </div>
          </Menu>
        </div>
      </div>
    </div>
  )
}
