import React from "react"
import { useHistory } from "react-router"

type Props = {
  title: string
  addNewTitle?: string
  addNewLink?: string
  onClick?: () => void
}

export const PageTitle: React.ComponentType<Props> = ({
  title,
  addNewLink,
  addNewTitle,
  onClick,
}) => {
  const history = useHistory()

  const handleClick = () => {
    if (onClick) {
      onClick()
      return
    }
    if (addNewLink) {
      history.push(addNewLink)
    }
  }
  return (
    <div className="flex py-5 items-center justify-between">
      <div className={"font-semibold text-3xl"}>{title}</div>
      {addNewLink && (
        <div
          className="text-primary-blue border px-3 py-1 text-sm font-medium border-primary-blue rounded-sm cursor-pointer"
          onClick={handleClick}
        >
          {addNewTitle}
        </div>
      )}
    </div>
  )
}
