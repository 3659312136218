import React, { useState, useEffect, useCallback } from "react"
import { client } from "../../utilities/Apollo"
import { Loader } from "../../component/atoms/Loader"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import GET_CONFIGS from "../../query/Config/getConfigs"
import { LoaderButton } from "../../component/atoms/LoaderButton"
import UPDATE_CONFIG from "../../query/Config/updateConfig"
import { Wrapper } from "../../component/organisms/Wrapper"
import { ApolloError, FetchResult } from "@apollo/client"
import { Config } from "../../types/Config"
import { useFormik, FormikErrors } from "formik"
import TextField from "@material-ui/core/TextField"
import { REQUIRED } from "../../constants/constants"
import { useDispatch } from "../../store/hooks"
import { snackbarSlice } from "../../store/slices/snackbarSlice"
import { useSelector } from "../../store/hooks"
import { BlueWrapper } from "../../component/atoms/BlueWrapper"
import { PageTitle } from "../../component/atoms/PageTitle"

interface ConfigProps {
  config: Config
}
interface FormValues {
  value: string
}
const ConfigItem = (props: ConfigProps) => {
  const { config } = props
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const validate = (values: FormValues) => {
    let errors: FormikErrors<FormValues> = {}
    if (!values.value) {
      errors.value = REQUIRED
    }

    return errors
  }

  const formik = useFormik({
    initialValues: {
      value: config.value,
    },
    validate,
    onSubmit: () => {
      updateConfig()
    },
  })

  const updateConfig = async () => {
    setLoading(true)
    await client
      .mutate({
        mutation: UPDATE_CONFIG,
        variables: { id: config.id, value: formik.values.value },
      })
      .then(() => onUpdateConfigSuccess())
      .catch(error => onUpdateError(error))
  }

  const onUpdateConfigSuccess = async () => {
    setLoading(false)
    //handleOpen({ severity: "success", message: "Konfigurace uložena" })
    dispatch(
      snackbarSlice.actions.put({
        severity: "success",
        message: "Konfigurace uložena",
        open: true,
      })
    )
  }

  const onUpdateError = (error: ApolloError) => {
    setLoading(false)
    console.log(error)
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        required
        name="value"
        label={config.identifier}
        value={formik.values.value}
        variant="outlined"
        margin="dense"
        fullWidth
        onChange={formik.handleChange}
        helperText={formik.errors.value || " "}
        error={(formik.errors.value && formik.touched.value) || undefined}
      />
      <LoaderButton
        loading={loading}
        primary={true}
        type="submit"
        title={"Uložit"}
        onClick={() => {}}
      />
    </form>
  )
}
interface DataProps {
  configs: [Config] | []
}
const DataContainer = (props: DataProps) => {
  const { configs } = props

  return (
    <div className="grid grid-cols-3 gap-6">
      {configs.map((config: Config, index: number) => (
        <div key={index}>
          <Card elevation={1}>
            <CardContent>
              <ConfigItem config={config} />
            </CardContent>
          </Card>
        </div>
      ))}
    </div>
  )
}

type Props = {}
export const Configuration: React.ComponentType<Props> = () => {
  const { user } = useSelector(state => state.user)

  const [loading, setLoading] = useState(true)
  const [configs, setConfigs] = useState<[Config] | []>([])

  const getConfigs = useCallback(async () => {
    setLoading(true)
    await client
      .query({ query: GET_CONFIGS })
      .then(response => onDataSuccess(response))
      .catch(error => onDataError(error))
  }, [])
  useEffect(() => {
    async function fetchData() {
      getConfigs()
    }
    fetchData()
  }, [getConfigs])

  const onDataSuccess = (response: FetchResult) => {
    setLoading(false)
    setConfigs(response?.data?.getConfigs)
  }

  const onDataError = (error: ApolloError) => {
    console.log(error)
  }

  return (
    <Wrapper user={user}>
      <BlueWrapper>
        <PageTitle title={"Konfigurace"} />
        {loading && <Loader />}
        {!loading && <DataContainer configs={configs} />}
      </BlueWrapper>
    </Wrapper>
  )
}
