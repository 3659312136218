import React, { useState } from "react"
import TextField from "@material-ui/core/TextField"
import BuddyLogo from "../../assets/buddyvet-new-logo.svg"
import Container from "@material-ui/core/Container"
import { client, publicClient } from "../../utilities/Apollo"
import LOGIN_USER from "../../query/Auth/loginUser"
import { useDispatch } from "../../store/hooks"
import { userSlice } from "../../store/slices/userSlice"
import { useHistory } from "react-router-dom"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import { LoaderButton } from "../../component/atoms/LoaderButton"
import GET_CURRENT_USER from "../../query/User/getCurrent"
import { Copyright } from "../../component/atoms/Copyright"
import Alert from "@material-ui/lab/Alert"
import { useFormik, FormikErrors } from "formik"
import { REQUIRED } from "../../constants/constants"
import { ApolloError, FetchResult } from "@apollo/client"
import styled from "styled-components"
import { withTheme } from "@material-ui/core/styles"

type Props = {}

interface FormValues {
  email: string
  password: string
}

const Wrapper = styled.div`
  background-color: #f3f7fc;
  height: 100vh;
  display: flex;
  align-items: center;
`

const StyledLoaderButton = withTheme(styled(LoaderButton)`
  margin: ${props => props.theme.spacing(3, 0, 2)};
`)

const Logo = styled.img`
  max-width: 250px;
`

const LogoCenter = styled.div`
  text-align: center;
  display: block;
`

const StyledCardActions = styled(CardActions)`
  justify-content: center;
`

export const Login: React.ComponentType<Props> = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const validate = (values: FormValues) => {
    let errors: FormikErrors<FormValues> = {}
    if (!values.email) {
      errors.email = REQUIRED
    }
    if (!values.password) {
      errors.password = REQUIRED
    }
    return errors
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: () => {
      signIn()
    },
  })

  const signIn = async () => {
    setLoading(true)
    setError(null)
    await publicClient
      .mutate({
        mutation: LOGIN_USER,
        variables: {
          email: formik.values.email,
          password: formik.values.password,
        },
      })
      .then(response => onSignInSuccess(response))
      .catch(error => onSignInError(error))
  }

  const onSignInSuccess = async (response: FetchResult) => {
    let token = response?.data?.loginAdmin
    if (token) {
      localStorage.setItem("token", token)
      await getCurrentUser()
    }
  }

  const onSignInError = (error: ApolloError) => {
    setLoading(false)
    setError(error.graphQLErrors[0].message)
  }

  const getCurrentUser = async () => {
    await client
      .query({ query: GET_CURRENT_USER })
      .then(response => onGetCurrentUserSuccess(response))
      .catch(() => onGetCurrentError())
  }

  const onGetCurrentUserSuccess = async (response: FetchResult) => {
    setLoading(false)
    dispatch(
      userSlice.actions.put({
        initialized: true,
        user: response?.data?.currentAdminDoctor,
      })
    )

    history.push("/dashboard")
  }

  const onGetCurrentError = () => {
    setLoading(false)
  }

  return (
    <Wrapper>
      <Container component={"main"} maxWidth="xs">
        <Card>
          <CardContent>
            <LogoCenter>
              <Logo src={BuddyLogo} alt="BuddyVET logo" className="mx-auto" />
            </LogoCenter>
            {error && <Alert severity="error">{error}</Alert>}
            <form onSubmit={formik.handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Emailová adresa"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                helperText={formik.errors.email || " "}
                error={
                  (formik.errors.email && formik.touched.email) || undefined
                }
                autoComplete="email"
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                helperText={formik.errors.password || " "}
                error={
                  (formik.errors.password && formik.touched.password) ||
                  undefined
                }
                label="Heslo"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <StyledLoaderButton
                loading={loading}
                title={"Přihlásit se"}
                onClick={signIn}
                primary={true}
              />
            </form>
          </CardContent>

          <LogoCenter>
            <StyledCardActions>
              <Copyright />
            </StyledCardActions>
          </LogoCenter>
        </Card>
      </Container>
    </Wrapper>
  )
}
