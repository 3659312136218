import { PayloadAction } from "@reduxjs/toolkit"
import { mergeDeepRight } from "ramda"

type PartialAll<T> = T extends object
  ? { [K in keyof T]?: PartialAll<T[K]> }
  : T

export const objectReducerGenerator = <T>() => {
  return {
    put: (_: T, { payload }: PayloadAction<T>) => payload,
    update: (state: object, { payload }: PayloadAction<PartialAll<object>>) =>
      mergeDeepRight(state, payload) as T,
  }
}
