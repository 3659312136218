import React from "react"
import { BlueWrapper } from "../../component/atoms/BlueWrapper"
import { DoctorForm } from "../../component/molecules/DoctorForm"
import { Wrapper } from "../../component/organisms/Wrapper"
import { useSelector } from "../../store/hooks"

type Props = {}

export const DoctorCreate: React.ComponentType<Props> = () => {
  const { user } = useSelector(state => state.user)

  return (
    <Wrapper user={user}>
      <BlueWrapper>
        <DoctorForm />
      </BlueWrapper>
    </Wrapper>
  )
}
